import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TextField, Button, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import axios from 'axios';
import moment from 'moment';
import OrderSingleProduct from "../Component/OrderSingleProduct";

const PlaceOrder = ({ user, items, cartTotal, freeDeliveryAt, deliveryPrice, apiUrl, emptyCart, setOrder, confirmOrderSetStep }) => {

    // const formik = useFormik({
    //     initialValues: {
    //         client: {
    //             fullName: "",
    //             email: "",
    //             address: "",
    //             phone: "",
    //             phoneTwo: "",
    //             mf: "",
    //             deleg_id: "",
    //         }
    //     },
    //     validationSchema: yup.object().shape({
    //         client: yup.object().shape({
    //             fullName: yup.string().required('Full Name is required'),
    //             phone: yup.number()
    //                 .test('phone', "Phone must be 8 digits", (value) => value ? value.toString().length === 8 : false)
    //                 .required('Phone is required'),
    //         })
    //     }),
    //     onSubmit: (values) => {
    //         const formData = new FormData();
    //         formData.append('client[clientId]', user.id);
    //         formData.append('order[deliveryDate]', moment().add(1, 'd'));
    //         formData.append('order[discount]', 0);
    //         formData.append('order[store_id]', 2);
    //         formData.append('order[packageSize]', 1);
    //         formData.append('order[paymentStatus]', 2);
    //         formData.append('order[amount]', freeDeliveryAt <= cartTotal ? cartTotal : cartTotal + deliveryPrice);
    //         formData.append('order[descProduit]', "Jouet Educatifs");
    //
    //         items.forEach((ele, index) => {
    //             formData.append(`items[${index}][product_id]`, ele.id);
    //             formData.append(`items[${index}][quantity]`, ele.quantity);
    //         });
    //
    //         if (cartTotal >= freeDeliveryAt) {
    //             formData.append('order[free_delivery]', 1);
    //         }
    //
    //         formData.append('order[delivery_price]', deliveryPrice);
    //
    //         axios.post(apiUrl + "front/orders/create", formData)
    //             .then((res) => {
    //                 emptyCart();
    //                 setOrder(res.data.order);
    //                 confirmOrderSetStep(2);
    //             })
    //             .catch((e) => console.log(e));
    //     }
    // });

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <OrderSingleProduct/>
        </motion.div>
    );
};

export default PlaceOrder;

import React, {useEffect, useRef, useState} from "react";
import {Box, Typography, Grid, Container, Fab, Button, TextField, Snackbar, Alert} from "@mui/material";
import { motion } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Carousel styles
import OrderSingleProduct from "../Component/OrderSingleProduct";
import { Helmet } from "react-helmet";
import AnimatedPapers from "../Component/calendar/AnimatedPapers";
import AnimatedCalendarText from "../Component/calendar/AnimatedText";
import AnimatedTypography from "../Component/calendar/AnimatedTypography";
import ClientReviews from "../Component/calendar/ClientReviews";
import axios from "axios";
import {getRandomItem, injectProductsIntoThemes} from "../functions/helper";
import {themes} from "../json/memory_game/themes";
import {useCart} from "react-use-cart";
import AddProductToCard from "../Component/AddProductToCard";
// Initialize Facebook Pixel

const images = [
    "/calendar/1.jpg",
    "/calendar/2.jpg",
    "/calendar/3.jpg",
    "/calendar/4.jpg",
    "/calendar/5.jpg",
    "/calendar/6.jpg",
    "/calendar/7.jpg",
    "/calendar/8.jpg",
    "/calendar/9.jpg",
    "/calendar/10.jpg",
    "/calendar/11.jpg",
    "/calendar/12.jpg",
];

const sectionImages = [
    "/calendar/8.jpg", // Feature 1 image
    "/calendar/13.jpg", // Feature 2 image
    "/calendar/7.jpg", // Feature 3 image
];

const ProductCard = ({ product }) => {
    const { addItem } = useCart();
    const [quantity, setQuantity] = useState(1);
    const [discountMessage, setDiscountMessage] = useState("");
    const [nextDiscountMessage, setNextDiscountMessage] = useState("");

    // Calculate discount based on quantity
    const getDiscount = (quantity) => {
        if (quantity >= 2 && quantity <= 3) return { discount: 0.05, message: "🎉 Réduction de 5% appliquée !" };
        if (quantity >= 4 && quantity <= 10) return { discount: 0.10, message: "🔥 Réduction de 10% appliquée !" };
        if (quantity > 10) return { discount: 0.15, message: "🚀 Réduction de 15% appliquée !" };
        return { discount: 0, message: "" };
    };

    // Get message for the next discount based on quantity
    const getNextDiscountMessage = (quantity) => {
        if (quantity === 1) return "Achetez 2 et obtenez 5% de réduction !";
        if (quantity >= 2 && quantity <= 3) return "Achetez 4 et obtenez 10% de réduction !";
        if (quantity >= 4 && quantity <= 10) return "Achetez plus pour 15% de réduction !";
        return "";
    };

    // Handle quantity change
    const handleQuantityChange = (e) => {
        const newQuantity = Math.max(1, parseInt(e.target.value) || 1);
        setQuantity(newQuantity);

        // Update discount messages
        const { message } = getDiscount(newQuantity);
        setDiscountMessage(message);
        setNextDiscountMessage(getNextDiscountMessage(newQuantity));
    };

    // Calculate discounted price
    const getDiscountedPrice = (price, quantity) => {
        const { discount } = getDiscount(quantity);
        return price - price * discount;
    };

    // Add item to cart
    const handleAddToCart = () => {
        const discountedPrice = getDiscountedPrice(product.price, quantity);
        addItem({
            id: product.id,
            name: product.name,
            price: discountedPrice,
            quantity: quantity,
            image: product.image,
        });
    };

    return (
        <Box
            sx={{
                p: 3,
                border: "1px solid #ddd",
                borderRadius: 2,
                textAlign: "center",
                maxWidth: 350,
                mx: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: 3,
                bgcolor: "white",
            }}
        >
            <Typography variant="h6">{product.name}</Typography>
            <Typography variant="body1">Prix : {product.price} TND</Typography>

            {/* Next Discount Message */}
            {nextDiscountMessage && (
                <Box
                    sx={{
                        mt: 2,
                        p: 1,
                        bgcolor: "#fff3e0",
                        color: "#e65100",
                        borderRadius: 1,
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                        textAlign: "center",
                        width: "100%",
                    }}
                >
                    {nextDiscountMessage}
                </Box>
            )}

            {/* Quantity Selection */}
            <TextField
                type="number"
                label="Quantité"
                variant="outlined"
                size="small"
                sx={{ my: 2, width: "100px" }}
                value={quantity}
                onChange={handleQuantityChange}
            />

            {/* Current Discount Message */}
            {discountMessage && (
                <Box
                    sx={{
                        mt: 1,
                        p: 1,
                        bgcolor: "#e0f7fa",
                        color: "#00796b",
                        borderRadius: 1,
                        fontSize: "0.9rem",
                        fontWeight: "bold",
                        textAlign: "center",
                        width: "100%",
                    }}
                >
                    {discountMessage}
                </Box>
            )}

            {/* Show Discounted Price */}
            <Typography variant="body2" color="green" sx={{ mt: 1 }}>
                Prix total : {(getDiscountedPrice(product.price, quantity) * quantity).toFixed(2)} TND
            </Typography>

            {/* Add to Cart Button */}
            <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleAddToCart}>
                Ajouter au panier
            </Button>
        </Box>
    );
};


const Calendar2025 = () => {



    const getDiscount = (quantity) => {
        if (quantity >= 2 && quantity < 3) return { discount: 0.05, message: "🎉 Réduction de 5% appliquée !" };
        if (quantity >= 4 && quantity < 10) return { discount: 0.10, message: "🔥 Réduction de 10% appliquée !" };
        if (quantity > 9) return { discount: 0.15, message: "🚀 Réduction de 15% appliquée !" };
        return { discount: 0, message: "" };
    };

    // Get message for the next discount based on quantity
    const getNextDiscountMessage = (quantity) => {
        if (quantity === 1) return "Achetez 2 et obtenez 5% de réduction !";
        if (quantity >= 2 && quantity <= 3) return "Achetez 4 et obtenez 10% de réduction !";
        if (quantity >= 4 && quantity <= 10) return "Achetez 10 pour 15% de réduction !";
        return "";
    };

    // // Handle quantity change
    // const handleQuantityChange = (e) => {
    //     const newQuantity = Math.max(1, parseInt(e.target.value) || 1);
    //     setQuantity(newQuantity);
    //
    //     // Update discount messages
    //     const { message } = getDiscount(newQuantity);
    //     setDiscountMessage(message);
    //     setNextDiscountMessage(getNextDiscountMessage(newQuantity));
    // };
    //
    // Calculate discounted price
    const getDiscountedPrice = (price, quantity) => {
        const { discount } = getDiscount(quantity);
        return price - price * discount;
    };

    // Reference to the reviews section
    const orderSectionRef = useRef(null);
    const [isOrderSectionInView, setIsOrderSectionInView] = useState(false);
    const [product, setProduct] = useState(null);

    const fetchProducts = async () => {
        try {
            const response = await axios.get(`https://api.sghiri.tn/front/products/multiple/172`, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            setProduct(response.data.product)

        } catch (err) {

        }
    };
    useEffect(() => {

        fetchProducts();
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsOrderSectionInView(entry.isIntersecting);
            },
            {
                root: null, // Use the viewport as the root
                threshold: 0.1, // Trigger when 10% of the element is in view
            }
        );

        if (orderSectionRef.current) {
            observer.observe(orderSectionRef.current);
        }

        // Cleanup observer on component unmount
        return () => {
            if (orderSectionRef.current) {
                observer.unobserve(orderSectionRef.current);
            }
        };
    }, []);
    // Scroll to the reviews section
    const handleScrollToReviews = () => {
        if (orderSectionRef.current) {
            orderSectionRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const bounceAnimation = {
        animate: {
            y: [0, -10, 0], // Move up and down
            position: "fixed",
            bottom: 16,
            right: 16,
            zIndex: 999,
        },
        transition: {
            duration: 1, // 1 second for one bounce cycle
            repeat: Infinity, // Repeat the animation indefinitely
            repeatType: 'loop',
            repeatDelay : 1// Loop the animation
        },
    };
    return (
        <Box>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>يومية صغيري 2025</title>
                <link rel="canonical" href="https://www.sghiri.tn/calendrier-2025"/>
                <meta property="og:image" content="/calendar/1.jpg"/>
            </Helmet>
            {/* Floating Button */}
            {!isOrderSectionInView && (
                <motion.div {...bounceAnimation}>
                    <Button
                        color="success"
                        variant="contained"
                        sx={{
                            position: "fixed",
                            bottom: 16,
                            right: 16,
                            zIndex: 999,
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                            padding: "8px 16px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            textTransform: "none",
                        }}
                        onClick={handleScrollToReviews}
                    >
                        <span style={{fontSize: "16px", fontWeight: "bold"}}>COMMANDER</span>
                        <span style={{fontSize: "12px", color: "rgba(255, 255, 255, 0.8)"}}>السعر 20 دينار</span>
                    </Button>
                </motion.div>
            )}
            {/* Header Section */}
            <Box
                sx={{
                    py: 4,
                    textAlign: "center",
                }}
            >
                <motion.img
                    src="/calendar/logo.png"
                    alt="Logo"
                    style={{width: 200}}
                    initial={{y: -100, opacity: 0}}
                    animate={{y: 0, opacity: 1}}
                    transition={{
                        type: "spring",
                        stiffness: 500,
                        damping: 20,
                        duration: 0.5,
                        bounce: 0.5,
                    }}
                />

            </Box>
            <AnimatedCalendarText/>

            <AnimatedPapers/>

            <AnimatedTypography/>

            <Box
                sx={{
                    marginTop: 4,
                    textAlign: "center",
                }}
            >
                <Typography
                    variant="h3"
                    dir={'rtl'}
                    sx={{
                        fontSize: {xs: "1.8rem", md: "2.5rem"},
                        fontWeight: "700",
                        color: "#333",
                        marginBottom: "0.5rem",
                    }}
                >
                    صور اليومية
                </Typography>
            </Box>

            {/* Carousel Section */}
            <Box
                sx={{
                    my: 5,
                    mx: "auto",
                    maxWidth: 300,
                    borderRadius: 2,
                    overflow: "hidden",
                }}
            >
                <Carousel
                    autoPlay
                    infiniteLoop
                    interval={2000}
                    showThumbs={false}
                    showStatus={false}
                    emulateTouch={true}
                    swipeable={false}
                >
                    {images.map((src, index) => (
                        <Box key={index} sx={{position: "relative"}}>
                            <img
                                src={src}
                                alt={`Planner ${index + 1}`}
                                style={{width: "100%", borderRadius: 4}}
                            />
                        </Box>
                    ))}
                </Carousel>

            </Box>

            <Grid container item justifyContent="center" sx={{marginTop: 4}}>
                <motion.Box
                    initial={{opacity: 0, y: -20}}
                    animate={{opacity: 1, y: 0}}
                    style={{
                        zIndex: 1000,
                        padding: '10px 20px',
                        backgroundColor: '#FFC107',
                        color: 'black',
                        borderRadius: '4px',
                        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                    }}

                >
                    <Typography textAlign="center" variant="h6" component="div">
                        الكميّات شارفت على النفاذ ! لا تضيّع الفرصة على طفلك لسنة مليئة بالفائدة و المتعة

                    </Typography>
                </motion.Box>
            </Grid>

            {/* Features Section */}

            <ClientReviews/>
            <Grid container item justifyContent="center" sx={{marginTop: 4}}>
                <motion.Box
                    initial={{opacity: 0, y: -20}}
                    animate={{opacity: 1, y: 0}}
                    style={{
                        zIndex: 1000,
                        padding: '10px 20px',
                        backgroundColor: '#FFC107',
                        color: 'black',
                        borderRadius: '4px',
                        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                    }}

                >
                    <Typography textAlign="center" variant="h6" component="div">
                        الكميّات شارفت على النفاذ ! لا تضيّع الفرصة على طفلك لسنة مليئة بالفائدة و المتعة

                    </Typography>
                </motion.Box>
            </Grid>
            {/* Call to Action Section */}
            <Box
                ref={orderSectionRef}
                sx={{
                    textAlign: "center",
                    py: 5,
                    mt: 5,
                }}
            >
                <Typography dir="rtl" variant="h4" sx={{fontWeight: "bold", mb: 2}}>
                    احصل على يوميّتك الآن بـ 20 دينار فقط!
                </Typography>
                {
                    product && <AddProductToCard getDiscountedPrice={getDiscountedPrice} product={product[0]}
                                                 discountMessage={getDiscount}
                                                 nextDiscountMessage={getNextDiscountMessage}/>
                }
            </Box>
        </Box>
    );
};

export default Calendar2025;

import React from "react";
import {
    Box,
    Typography,
    Button,
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
} from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const products = [
    {
        name: "يومية 2025",
        price: "20 TND",
        image: "/calendar/1.jpg",
        link: "/calendrier-2025",
    },
    {
        name: "مفكرّة رمضان 2025",
        price: "17 TND",
        image: "/ramadanNotebook/m1_1.webp",
        link: "/ramadhan-notebook",
    },
];

const HomePageMosaic = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ textAlign: "center", p: 4, backgroundColor: "#f8f9fa" }}>
            {/* Title */}
            <Typography
                variant="h3"
                sx={{
                    fontWeight: "bold",
                    color: "#2c3e50",
                    mb: 4,
                    textTransform: "uppercase",
                    letterSpacing: 1.5,
                }}
            >
                Nos Produits
            </Typography>

            {/* Products Grid */}
            <Grid container spacing={4} justifyContent="center">
                {products.map((product, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <motion.div
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <Card
                                sx={{
                                    borderRadius: 2,
                                    boxShadow: 3,
                                    transition: "transform 0.3s ease-in-out",
                                    '&:hover': {
                                        transform: "scale(1.05)",
                                    },
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="300"
                                    image={product.image}
                                    alt={product.name}
                                    sx={{ objectFit: "cover" }}
                                />
                                <CardContent>
                                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                                        {product.name}
                                    </Typography>
                                    <Typography variant="h6" color="primary" sx={{ mt: 1 }}>
                                        {product.price}
                                    </Typography>
                                </CardContent>
                                <CardActions sx={{ justifyContent: "center", pb: 3 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{
                                            backgroundColor: "#2c3e50",
                                            '&:hover': { backgroundColor: "#34495e" },
                                        }}
                                        onClick={() => navigate(product.link)}
                                    >
                                        Voir le produit
                                    </Button>
                                </CardActions>
                            </Card>
                        </motion.div>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default HomePageMosaic;
import React from 'react';
import { Grid, Typography, Button, Card, CardContent, Box, TextField, Stack, Tooltip } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useCart } from 'react-use-cart';
import ShowCart from "../common/ShowCart"; // Import useCart hook

const RamadanNotebookModels = ({ notebookModels, selectModelRef }) => {
    const { addItem, updateItemQuantity, items } = useCart(); // Destructure cart methods

    // Handle quantity change for a specific model
    const handleQuantityChange = (model, newQuantity) => {
        const cartItem = items.find((item) => item.id === model.id);

        if (cartItem) {
            // If the item is already in the cart, update its quantity
            updateItemQuantity(model.id, newQuantity);
        } else {
            // If the item is not in the cart, add it with the specified quantity
            addItem({ ...model, quantity: newQuantity, price: 17 });
        }
    };

    // Handle form submission
    const handleSubmit = () => {
        if (items.length === 0) {
            alert('الرجاء اختيار كمية واحدة على الأقل.');
            return;
        }
        console.log('Selected Models:', items);
        alert('تم اختيار الموديلات بنجاح!');
    };

    return (
        <Grid ref={selectModelRef} container spacing={4} justifyContent="center" sx={{ p: 4, bgcolor: '#fafafa', minHeight: '100vh' }}>
            <Grid item xs={12}>
                <Typography variant="h2" align="center" sx={{ mb: 4, fontWeight: 'bold', color: '#4a148c' }}>
                    اختر موديلات مفكرة رمضان
                </Typography>
                <Typography variant="subtitle1" align="center" sx={{ mb: 4, color: '#757575' }}>
                    اختر الكمية المناسبة لكل موديل. يمكنك تعديل الكمية باستخدام الأزرار (+/-) أو كتابتها مباشرة.
                </Typography>
            </Grid>

            {/* Models Grid */}
            <Grid container spacing={4} justifyContent="center">
                {notebookModels.map((model, index) => {
                    const cartItem = items.find((item) => item.id === model.id);
                    const quantity = cartItem ? cartItem.quantity : 0;

                    return (
                        <Grid item xs={12} sm={6} md={4} key={model.id}>
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.2 }}
                                viewport={{ once: true }}
                            >
                                <Card sx={{ p: 3, boxShadow: 3, borderRadius: 2, bgcolor: '#ffffff', transition: 'transform 0.3s', '&:hover': { transform: 'scale(1.03)' } }}>
                                    <Typography variant="h4" align="center" sx={{ fontWeight: 'bold', color: 'black', mb: 2 }}>
                                        {model.name}
                                    </Typography>
                                    <Box
                                        component="img"
                                        src={model.image}
                                        alt={model.name}
                                        sx={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: '8px', mb: 2 }}
                                    />
                                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                                        <Tooltip title="تقليل الكمية" arrow>
                                            <Button
                                                variant="outlined"
                                                onClick={() => handleQuantityChange(model, quantity - 1)}
                                                disabled={quantity === 0}
                                                sx={{ color: 'black', borderColor: 'black', '&:hover': { bgcolor: 'black', color: '#ffffff' } }}
                                            >
                                                -
                                            </Button>
                                        </Tooltip>
                                        <TextField
                                            type="number"
                                            value={quantity}
                                            onChange={(e) => handleQuantityChange(model, parseInt(e.target.value))}
                                            inputProps={{ min: 0 }}
                                            sx={{ width: '80px', textAlign: 'center' }}
                                        />
                                        <Tooltip title="زيادة الكمية" arrow>
                                            <Button
                                                variant="outlined"
                                                onClick={() => handleQuantityChange(model, quantity + 1)}
                                                sx={{ color: 'black', borderColor: 'black', '&:hover': { bgcolor: 'black', color: '#ffffff' } }}
                                            >
                                                +
                                            </Button>
                                        </Tooltip>
                                    </Stack>
                                    {quantity > 0 && (
                                        <Typography variant="body2" align="center" sx={{ mt: 2, color: '#4caf50' }}>
                                            تم اختيار {quantity} من هذا الموديل.
                                        </Typography>
                                    )}
                                </Card>
                            </motion.div>
                        </Grid>
                    );
                })}
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12} sx={{ textAlign: 'center', mt: 4 }}>
                <motion.div
                    dir={"ltr"}
                    initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 }}
                    viewport={{ once: true }}
                >
                    <ShowCart />
                    <Tooltip title="اضغط هنا بعد اختيار الكميات المطلوبة" arrow>
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ mt: 2, bgcolor: 'black', '&:hover': { bgcolor: '#4a148c' } }}
                        >
                            تأكيد الطلب
                        </Button>
                    </Tooltip>
                </motion.div>
            </Grid>
        </Grid>
    );
};

export default RamadanNotebookModels;
import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Container, Paper, IconButton, Stack } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams, useOutletContext } from 'react-router-dom';
import { VolumeUp, CheckCircle, Cancel, RestartAlt, Replay } from '@mui/icons-material';
import arabicLetters from '../../data/arabicLetters.json';
import frenchLetters from '../../data/frenchLetters.json';
import englishLetters from '../../data/englishLetters.json';

// Language data mapping
const languageData = {
    arabic: arabicLetters,
    french: frenchLetters,
    english: englishLetters,
};

// Localized text remains unchanged (omitted for brevity)
const localizedText = {
    arabic: {
        title: (l) => `عدد حرف ${l}`,
        instructions: 'استمع إلى الكلمة وعد كم مرة يظهر الحرف فيها.',
        round: (r, t) => `الجولة ${r} من ${t}`,
        selectCount: 'اختر العدد',
        correct: (p) => `صحيح! +${p} نقاط`,
        wrong: 'خطأ! -2 نقاط',
        completed: '🎉 لقد أنهيت اللعبة!',
        finalScore: 'النقاط النهائية',
        playAgain: 'اللعب مجددا',
        start: 'بدء اللعبة',
        score: 'النقاط',
        streak: (s) => `تتابع ${s}x`,
    },
    french: {
        title: (l) => `Compter la lettre ${l}`,
        instructions: 'Écoutez le mot et comptez combien de fois la lettre apparaît.',
        round: (r, t) => `Tour ${r} sur ${t}`,
        selectCount: 'Choisissez le nombre',
        correct: (p) => `Correct! +${p} points`,
        wrong: 'Faux! -2 points',
        completed: '🎉 Vous avez terminé le jeu!',
        finalScore: 'Score final',
        playAgain: 'Rejouer',
        start: 'Commencer le jeu',
        score: 'Score',
        streak: (s) => `Série ${s}x`,
    },
    english: {
        title: (l) => `Count the Letter ${l}`,
        instructions: 'Listen to the word and count how many times the letter appears.',
        round: (r, t) => `Round ${r} of ${t}`,
        selectCount: 'Select the count',
        correct: (p) => `Correct! +${p} points`,
        wrong: 'Wrong! -2 points',
        completed: '🎉 You completed the game!',
        finalScore: 'Final Score',
        playAgain: 'Play Again',
        start: 'Start Game',
        score: 'Score',
        streak: (s) => `${s}x Streak`,
    },
};
const LetterCountGame = () => {
    const { language, letter } = useParams();
    const { updateHighScore = () => {} } = useOutletContext() || {};

    const [gameState, setGameState] = useState({
        score: parseInt(localStorage.getItem(`letterCountScore_${language}`)) || 0,
        streak: 0,
        totalCorrect: parseInt(localStorage.getItem(`letterCountTotalCorrect_${language}`)) || 0,
        currentRound: 1,
        totalRounds: 10,
        currentWord: null,
        correctCount: 0,
        options: [0, 1, 2, 3],
        feedback: null,
        sound: true,
        loading: true,
        gameCompleted: false,
        wordList: [], // Added to store pre-selected words
    });

    const [gameStarted, setGameStarted] = useState(false);
    const [isAudioPlaying, setIsAudioPlaying] = useState(false);

    const currentLanguage = languageData[language] || englishLetters;
    const text = localizedText[language] || localizedText.english;

    // Persist score and total correct answers to localStorage
    useEffect(() => {
        localStorage.setItem(`letterCountScore_${language}`, gameState.score.toString());
        localStorage.setItem(`letterCountTotalCorrect_${language}`, gameState.totalCorrect.toString());
        updateHighScore(gameState.score);
    }, [gameState.score, gameState.totalCorrect, language, updateHighScore]);

    // Play audio when currentWord changes
    useEffect(() => {
        if (gameState.sound && gameState.currentWord?.sound) {
            try {
                const audio = new Audio(gameState.currentWord.sound);
                audio.play().catch((error) => console.error('Error playing audio:', error));
            } catch (error) {
                console.error('Error loading audio:', error);
            }
        }
    }, [gameState.currentWord, gameState.sound]);

    // Collect all words from the language data
    const getAllWords = () => {
        return currentLanguage.letters.flatMap((l) => l.correctWords);
    };

    // Count occurrences of the target letter in a word
    const countLetterInWord = (word, targetLetter) => {
        const lowerWord = word.toLowerCase();
        const lowerLetter = targetLetter.toLowerCase();
        return lowerWord.split('').filter((char) => char === lowerLetter).length;
    };

    // Start the game and pre-select words
    const startGame = () => {
        const allWords = getAllWords();
        const wordsWithLetter = allWords.filter(word => countLetterInWord(word.word, letter) > 0);
        const wordsWithoutLetter = allWords.filter(word => countLetterInWord(word.word, letter) === 0);

        // Shuffle function
        const shuffleArray = (array) => [...array].sort(() => Math.random() - 0.5);

        const shuffledWithLetter = shuffleArray(wordsWithLetter);
        const shuffledWithoutLetter = shuffleArray(wordsWithoutLetter);

        // Select up to 6 words with the letter and up to 4 without
        const selectedWithLetter = shuffledWithLetter.slice(0, Math.min(6, shuffledWithLetter.length));
        const selectedWithoutLetter = shuffledWithoutLetter.slice(0, Math.min(4, shuffledWithoutLetter.length));

        // Combine and shuffle the final list
        const wordList = shuffleArray([...selectedWithLetter, ...selectedWithoutLetter]);
        const initialWord = wordList[0];

        setGameState(prev => ({
            ...prev,
            wordList,
            currentRound: 1,
            currentWord: initialWord,
            correctCount: countLetterInWord(initialWord.word, letter),
            loading: false,
        }));
        setGameStarted(true);
    };

    // Replay the word's audio
    const replayAudio = () => {
        if (gameState.currentWord?.sound && !isAudioPlaying) {
            setIsAudioPlaying(true);
            const audio = new Audio(gameState.currentWord.sound);
            audio
                .play()
                .then(() => {
                    audio.onended = () => setIsAudioPlaying(false);
                })
                .catch((error) => {
                    console.error('Error playing audio:', error);
                    setIsAudioPlaying(false);
                });
        }
    };

    // Handle player's answer selection
    const handleAnswer = async (selectedCount) => {
        if (gameState.feedback || gameState.loading) return;

        const isCorrect = selectedCount === gameState.correctCount;
        let newScore = gameState.score;
        let newStreak = isCorrect ? gameState.streak + 1 : 0;
        let newTotalCorrect = gameState.totalCorrect;

        if (isCorrect) {
            newTotalCorrect += 1;
            const points = newTotalCorrect % 5 === 0 ? 4 : 2;
            newScore = Math.max(0, gameState.score + points);
        } else {
            newScore = Math.max(0, gameState.score - 2);
        }

        setGameState((prev) => ({
            ...prev,
            score: newScore,
            streak: newStreak,
            totalCorrect: newTotalCorrect,
            feedback: isCorrect ? 'correct' : 'wrong',
            loading: true,
        }));

        await new Promise((resolve) => setTimeout(resolve, 1500));
        setGameState((prev) => ({
            ...prev,
            feedback: null,
            loading: false,
        }));

        nextRound();
    };

    // Proceed to the next round or end the game
    const nextRound = () => {
        if (gameState.currentRound < gameState.totalRounds) {
            const nextRoundNum = gameState.currentRound + 1;
            const nextWord = gameState.wordList[nextRoundNum - 1];
            setGameState(prev => ({
                ...prev,
                currentRound: nextRoundNum,
                currentWord: nextWord,
                correctCount: countLetterInWord(nextWord.word, letter),
            }));
        } else {
            setGameState(prev => ({
                ...prev,
                gameCompleted: true,
            }));
        }
    };

    // Reset the game for a new session
    const resetGame = () => {
        setGameState({
            score: parseInt(localStorage.getItem(`letterCountScore_${language}`)) || 0,
            streak: 0,
            totalCorrect: parseInt(localStorage.getItem(`letterCountTotalCorrect_${language}`)) || 0,
            currentRound: 1,
            totalRounds: 10,
            currentWord: null,
            correctCount: 0,
            options: [0, 1, 2, 3],
            feedback: null,
            sound: true,
            loading: true,
            gameCompleted: false,
            wordList: [],
        });
        setGameStarted(false);
    };
    // Render the UI
    return (
        <Container
            maxWidth={false}
            sx={{
                direction: language === 'arabic' ? 'rtl' : 'ltr',
                background: 'linear-gradient(135deg, #FFD700 0%, #FFA500 100%)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <Box sx={{ width: '100%', maxWidth: 900, position: 'relative' }}>
                {/* Header with sound controls */}
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    alignItems={{ xs: 'stretch', sm: 'center' }}
                    sx={{ mb: 3, px: 2 }}
                >
                    <IconButton
                        onClick={() => setGameState((prev) => ({ ...prev, sound: !prev.sound }))}
                        sx={{
                            backgroundColor: gameState.sound ? '#32CD32' : '#FF4500',
                            '&:hover': { backgroundColor: gameState.sound ? '#228B22' : '#DC143C' },
                            boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
                        }}
                    >
                        <VolumeUp sx={{ fontSize: 32, color: '#fff' }} />
                    </IconButton>
                    <IconButton
                        onClick={replayAudio}
                        disabled={isAudioPlaying || !gameState.currentWord}
                        sx={{
                            backgroundColor: isAudioPlaying || !gameState.currentWord ? '#B0BEC5' : '#32CD32',
                            '&:hover': {
                                backgroundColor: isAudioPlaying || !gameState.currentWord ? '#B0BEC5' : '#228B22',
                            },
                            boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
                        }}
                    >
                        <Replay sx={{ fontSize: 32, color: '#fff' }} />
                    </IconButton>
                </Stack>

                {/* Main game content */}
                <Paper
                    elevation={6}
                    sx={{
                        p: { xs: 3, sm: 5 },
                        borderRadius: '30px',
                        background: '#fff',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        border: '4px solid #FF69B4',
                        boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                    }}
                >
                    <AnimatePresence>
                        {/* Instructions and round info */}
                        {gameStarted && !gameState.gameCompleted && (
                            <Box sx={{ textAlign: 'center', mb: 2 }}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontFamily: "'Poppins', sans-serif",
                                        color: '#FF4500',
                                        fontSize: { xs: '1rem', sm: '1.25rem' },
                                    }}
                                >
                                    {text.instructions}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        mt: 1,
                                        fontFamily: "'Poppins', sans-serif",
                                        color: '#666',
                                    }}
                                >
                                    {text.round(gameState.currentRound, gameState.totalRounds)}
                                </Typography>
                            </Box>
                        )}

                        {/* Score display */}
                        <Paper
                            elevation={3}
                            sx={{
                                px: 3,
                                py: 1.5,
                                borderRadius: '20px',
                                background: '#FF69B4',
                                color: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
                            }}
                        >
                            <Typography variant="h6" fontFamily="'Poppins', sans-serif">
                                {text.score}: <Box component="span" sx={{ fontWeight: 'bold' }}>{gameState.score}</Box>
                            </Typography>
                            {gameState.streak > 0 && (
                                <Typography variant="body1" sx={{ ml: 2, color: '#FFD700' }}>
                                    {text.streak(gameState.streak)}
                                </Typography>
                            )}
                        </Paper>

                        {/* Start screen */}
                        {!gameStarted ? (
                            <motion.div
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0 }}
                                style={{ textAlign: 'center' }}
                            >
                                <Typography
                                    variant="h2"
                                    sx={{
                                        mb: 4,
                                        fontFamily: "'Poppins', sans-serif",
                                        color: '#FF4500',
                                        fontSize: { xs: '2rem', sm: '3rem' },
                                    }}
                                >
                                    {text.title(letter.toUpperCase())}
                                </Typography>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={startGame}
                                    sx={{
                                        backgroundColor: '#32CD32',
                                        color: '#fff',
                                        borderRadius: '25px',
                                        px: 5,
                                        py: 2,
                                        fontSize: '1.5rem',
                                        fontFamily: "'Poppins', sans-serif",
                                        boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
                                        '&:hover': { backgroundColor: '#228B22' },
                                    }}
                                >
                                    {text.start}
                                </Button>
                            </motion.div>
                        ) : gameState.gameCompleted ? (
                            /* Completion screen */
                            <motion.div
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0 }}
                                style={{ textAlign: 'center' }}
                            >
                                <Typography
                                    variant="h3"
                                    sx={{
                                        mb: 4,
                                        fontFamily: "'Poppins', sans-serif",
                                        color: '#FF69B4',
                                        fontSize: { xs: '1.8rem', sm: '2.5rem' },
                                    }}
                                >
                                    {text.completed}
                                </Typography>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mb: 2,
                                        fontFamily: "'Poppins', sans-serif",
                                        color: '#FF4500',
                                    }}
                                >
                                    {text.finalScore}: {gameState.score}
                                </Typography>
                                <Button
                                    variant="contained"
                                    size="large"
                                    startIcon={<RestartAlt />}
                                    onClick={resetGame}
                                    sx={{
                                        backgroundColor: '#FF4500',
                                        color: '#fff',
                                        borderRadius: '25px',
                                        px: 5,
                                        py: 2,
                                        fontSize: '1.5rem',
                                        fontFamily: "'Poppins', sans-serif",
                                        boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
                                        '&:hover': { backgroundColor: '#DC143C' },
                                    }}
                                >
                                    {text.playAgain}
                                </Button>
                            </motion.div>
                        ) : (
                            /* Game play screen */
                            <>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        textAlign: 'center',
                                        mb: 3,
                                        fontFamily: "'Poppins', sans-serif",
                                        color: '#FF4500',
                                        fontSize: { xs: '1.5rem', sm: '2rem' },
                                    }}
                                >
                                    {gameState.currentWord?.word || ''}
                                </Typography>

                                {gameState.currentWord && (
                                    <motion.div
                                        initial={{ scale: 0.9 }}
                                        animate={{ scale: 1 }}
                                        transition={{ type: 'spring', stiffness: 100 }}
                                        style={{ textAlign: 'center' }}
                                    >
                                        <img
                                            src={gameState.currentWord.image}
                                            alt="Word visual"
                                            style={{
                                                width: '100%',
                                                maxWidth: 200,
                                                height: 'auto',
                                                borderRadius: '20px',
                                                border: '4px solid #FF69B4',
                                                boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                                                margin: '0 auto',
                                                display: 'block',
                                            }}
                                        />
                                    </motion.div>
                                )}

                                <Typography
                                    variant="h6"
                                    sx={{
                                        textAlign: 'center',
                                        mt: 2,
                                        mb: 2,
                                        fontFamily: "'Poppins', sans-serif",
                                        color: '#666',
                                    }}
                                >
                                    {text.selectCount}
                                </Typography>

                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: { xs: 'repeat(2, 1fr)', sm: 'repeat(4, 1fr)' },
                                        gap: 2,
                                        mt: 2,
                                    }}
                                >
                                    <AnimatePresence>
                                        {gameState.options.map((option) => (
                                            <motion.div
                                                key={option}
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                exit={{ opacity: 0 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    size="large"
                                                    onClick={() => handleAnswer(option)}
                                                    disabled={gameState.loading || gameState.feedback}
                                                    sx={{
                                                        backgroundColor: '#FF69B4',
                                                        color: '#fff',
                                                        borderRadius: '20px',
                                                        py: 2,
                                                        fontSize: '1.5rem',
                                                        fontFamily: "'Poppins', sans-serif",
                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                                        transition: 'transform 0.2s',
                                                        '&:hover': {
                                                            backgroundColor: '#FF1493',
                                                            transform: gameState.loading ? 'none' : 'scale(1.05)',
                                                        },
                                                        '&:disabled': {
                                                            backgroundColor: '#B0BEC5',
                                                            color: '#fff',
                                                        },
                                                    }}
                                                >
                                                    {option}
                                                </Button>
                                            </motion.div>
                                        ))}
                                    </AnimatePresence>
                                </Box>
                            </>
                        )}
                    </AnimatePresence>

                    {/* Feedback overlay */}
                    <AnimatePresence>
                        {gameState.feedback && !gameState.gameCompleted && (
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0 }}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    textAlign: 'center',
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                    borderRadius: '20px',
                                    p: 3,
                                }}
                            >
                                {gameState.feedback === 'correct' ? (
                                    <CheckCircle sx={{ fontSize: 100, color: '#32CD32' }} />
                                ) : (
                                    <Cancel sx={{ fontSize: 100, color: '#FF4500' }} />
                                )}
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mt: 2,
                                        fontFamily: "'Poppins', sans-serif",
                                        color: gameState.feedback === 'correct' ? '#32CD32' : '#FF4500',
                                    }}
                                >
                                    {gameState.feedback === 'correct'
                                        ? text.correct(gameState.totalCorrect % 5 === 0 ? 4 : 2)
                                        : text.wrong}
                                </Typography>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </Paper>
            </Box>
        </Container>
    );
};

export default LetterCountGame;
import React from "react";
import {
    Box,
    IconButton,
    Typography,
    Stack,
    Button,
    Tooltip,
    Grid,
} from "@mui/material";
import {
    Phone,
    Facebook,
    Instagram,
    Chat,
} from "@mui/icons-material";
import { motion } from "framer-motion";

const ContactIcons = () => {
    const phoneNumber = "55318152";

    // Animation variants for buttons
    const buttonVariants = {
        hover: { scale: 1.1, transition: { duration: 0.2 } },
        tap: { scale: 0.9 },
    };

    // Open Messenger chat
    const openMessenger = () => {
        window.open("https://m.me/sghiri.tn", "_blank");
    };

    // Open Facebook page
    const openFacebook = () => {
        window.open("https://www.facebook.com/sghiri.tn", "_blank");
    };

    // Open Instagram profile
    const openInstagram = () => {
        window.open("https://www.instagram.com/sghiritn/", "_blank");
    };

    return (
        <Box sx={{ textAlign: "center", p: 3 }}>
            <Grid container spacing={2} justifyContent="center">
                {/* Phone Number */}
                <Grid item xs={12} sm={6} md={3}>
                    <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<Phone />}
                            fullWidth
                            sx={{
                                backgroundColor: "#25D366",
                                '&:hover': { backgroundColor: "#128C7E" },
                                py: 2,
                            }}
                            onClick={() => window.location.href = `tel:${phoneNumber}`}
                        >
                            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                {phoneNumber}
                            </Typography>
                        </Button>
                    </motion.div>
                </Grid>

                {/* Messenger Button */}
                <Grid item xs={12} sm={6} md={3}>
                    <Tooltip title="Chat with us on Messenger" arrow>
                        <motion.div variants={buttonVariants} whileHover="hover" whileTap="tap">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Chat />}
                                fullWidth
                                sx={{
                                    backgroundColor: "#006AFF",
                                    '&:hover': { backgroundColor: "#0056b3" },
                                    py: 2,
                                }}
                                onClick={openMessenger}
                            >
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    Messenger
                                </Typography>
                            </Button>
                        </motion.div>
                    </Tooltip>
                </Grid>

                {/* Facebook Button */}
                <Grid item xs={12} sm={6} md={3}>
                    <Tooltip title="Visit our Facebook page" arrow>
                        <motion.div variants={buttonVariants} whileHover="hover" whileTap="tap">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Facebook />}
                                fullWidth
                                sx={{
                                    backgroundColor: "#1877F2",
                                    '&:hover': { backgroundColor: "#166FE5" },
                                    py: 2,
                                }}
                                onClick={openFacebook}
                            >
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    Facebook
                                </Typography>
                            </Button>
                        </motion.div>
                    </Tooltip>
                </Grid>

                {/* Instagram Button */}
                <Grid item xs={12} sm={6} md={3}>
                    <Tooltip title="Follow us on Instagram" arrow>
                        <motion.div variants={buttonVariants} whileHover="hover" whileTap="tap">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<Instagram />}
                                fullWidth
                                sx={{
                                    backgroundColor: "#E4405F",
                                    '&:hover': { backgroundColor: "#D8334A" },
                                    py: 2,
                                }}
                                onClick={openInstagram}
                            >
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    Instagram
                                </Typography>
                            </Button>
                        </motion.div>
                    </Tooltip>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ContactIcons;
import React, { useState, useEffect } from "react";
import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    IconButton,
    Typography,
    Stack,
    Box,
    Button,
    Container,
    Paper,
} from "@mui/material";
import {
    Add,
    Remove,
    Person,
    Phone,
    LocationOn,
    LocalShipping,
    ShoppingCart,
    ArrowBackIos,
    ArrowForwardIos,
    Info,
} from "@mui/icons-material"; // Added icons
import { useFormik } from "formik";
import * as Yup from "yup";
import { getIn } from "formik";
import { commonApiCall, frontApiCall } from "../api/config";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import { useCart } from "react-use-cart";
import { AnimatePresence, motion } from "framer-motion";
import ContactIcons from "../common/ContactIcons";
import axios from "axios";

export default function OrderSingleProduct() {
    const { items, updateItemQuantity, removeItem, emptyCart } = useCart();
    const [activeGov, setActiveGov] = useState({ gov: "", delegations: [] });
    const [govs, setGovs] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const pixelId = "715804273228523";
    ReactPixel.init(pixelId);
    ReactPixel.pageView();

    useEffect(() => {
        commonApiCall('')
            .get("/places/govs/all?withDeleg=true")
            .then((res) => setGovs(res.data))
            .finally(() => setLoading(false));
    }, []);

    // Function to track events via Conversion API
    const trackEvent = async (eventName, customData) => {
        try {
            const response = await axios.post('https://sghiriwebserver.onrender.com/track-event', {
                eventName,
                customData,
                clientIp: window.location.hostname === 'localhost' ? '127.0.0.1' : null, // Basic IP for testing
                userAgent: navigator.userAgent, // Browser user agent
            });
            console.log('Event tracked successfully:', response.data);
        } catch (error) {
            console.error('Error tracking event:', error.response ? error.response.data : error.message);
        }
    };


    const formik = useFormik({
        initialValues: {
            client: {
                fullName: "",
                phone: "",
                deleg_id: "",
                address: "",
            },
            items: items,
        },
        validationSchema: Yup.object({
            client: Yup.object({
                fullName: Yup.string().required("Nom complet est requis"),
                phone: Yup.string()
                    .required("Numéro de téléphone est requis")
                    .matches(/^[0-9]{8}$/, "Le numéro de téléphone doit contenir 8 chiffres"),
                address: Yup.string().required("Adresse est requise"),
                deleg_id: Yup.string().required("Délégation est requise"),
            }),
        }),
        onSubmit: (values, { setFieldError }) => {
            const data = new FormData();
            data.append('client[fullName]', values.client.fullName);
            data.append('client[deleg_id]', values.client.deleg_id);
            data.append('client[address]', values.client.address);
            data.append('client[phone]', values.client.phone);
            data.append('order[deliveryDate]', moment().format('YYYY-MM-DD'));
            data.append('order[discount]', 0);
            data.append('order[store_id]', 2);
            data.append('order[packageSize]', 1);
            data.append('order[paymentStatus]', 2);
            data.append('order[descProduit]', "Jeux educatifs");
            data.append('order[free_delivery]', 0);
            data.append('order[amount]', total);
            data.append('order[delivery_price]', 8);
            data.append('order[isExchange]', 0);

            items.forEach((item, index) => {
                data.append(`items[${index}][product_id]`, item.id);
                data.append(`items[${index}][quantity]`, item.quantity);
            });

            frontApiCall("").post('/orders/create', data)
                .then(res => {
                    // Track Purchase event
                    trackEvent('Purchase', {
                        value: total,
                        currency: 'TND',
                        content_ids: items.map(item => item.id),
                        content_type: 'product',
                        contents: items.map(item => ({
                            id: item.id,
                            quantity: item.quantity,
                            item_price: getDiscountedPrice(item),
                        })),
                    });
                    navigate(`/confirm-order/${res.data.order.id}`);
                    emptyCart();
                })
                .catch((error) => {
                    if (error.response.status === 400 && error.response.data["client.phone"]) {
                        setFieldError('client.phone', "Le téléphone client est déjà utilisé pour un autre client");
                    }
                });
        },
    });

    const filterSelectedGov = (id) => {
        formik.setFieldValue("client.deleg_id", "");
        const selectedGov = govs.find((gov) => gov.id === id);
        setActiveGov({ gov: selectedGov.id, delegations: selectedGov.delegations });
    };

    const handleQuantityChange = (itemId, newQuantity) => {
        updateItemQuantity(itemId, newQuantity);
    };

    const getDiscountedPrice = (product) => {
        if (!product.reduction || product.reduction.length === 0) return product.price;

        const applicableReduction = product.reduction
            .filter((reduction) => product.quantity >= reduction.quantity)
            .reduce((max, item) => Math.max(max, item.reduction), 0);

        return product.price - (applicableReduction / 100) * product.price;
    };

    const deliveryFee = 8;
    const subtotal = items.reduce((acc, item) => acc + item.quantity * getDiscountedPrice(item), 0);
    const total = Math.round(subtotal + deliveryFee);

    if (loading) return <Typography>Loading...</Typography>;

    const products = [
        {
            name: "يومية 2025",
            price: "20 TND",
            images: ['/calendar/1.jpg', '/calendar/2.jpg', '/calendar/3.jpg', '/calendar/4.jpg'],
            link: '/calendrier-2025'
        },
        {
            name: "مفكرّة رمضان 2025",
            price: "17 TND",
            images: ['/ramadanNotebook/m1_1.webp', '/ramadanNotebook/m2_1.webp', '/ramadanNotebook/m3_1.webp', '/ramadanNotebook/m4_1.webp', '/ramadanNotebook/m5_1.webp', '/ramadanNotebook/m6_1.webp'],
            link: '/ramadhan-notebook'
        }
    ];

    const ProductCarousel = ({ product }) => {
        const [currentIndex, setCurrentIndex] = useState(0);

        const handleNext = () => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % product.images.length);
        };

        const handlePrev = () => {
            setCurrentIndex((prevIndex) => (prevIndex - 1 + product.images.length) % product.images.length);
        };

        return (
            <Paper sx={{ position: 'relative', textAlign: 'center', padding: 2, borderRadius: 2, boxShadow: 3 }}>
                <AnimatePresence mode="wait">
                    <motion.img
                        key={product.images[currentIndex]}
                        src={product.images[currentIndex]}
                        alt={product.name}
                        style={{ width: '100%', height: '150px', objectFit: 'contain', borderRadius: 8 }}
                        initial={{ opacity: 0, x: 100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -100 }}
                        transition={{ duration: 0.5 }}
                    />
                </AnimatePresence>
                <Typography variant="h6" sx={{ mt: 1 }}>{product.name}</Typography>
                <Typography variant="subtitle1" color="primary">{product.price}</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 2 }}
                    onClick={() => window.location.href = product.link}
                >
                    Voir le produit
                </Button>
                <IconButton onClick={handlePrev} sx={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)' }}>
                    <ArrowBackIos />
                </IconButton>
                <IconButton onClick={handleNext} sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}>
                    <ArrowForwardIos />
                </IconButton>
            </Paper>
        );
    };

    return (
        <Box sx={{ backgroundColor: "#f8f9fa", minHeight: "100vh", py: 4 }}>
            <Box sx={{ maxWidth: "1200px", mx: "auto", px: { xs: 2, md: 4 } }}>
                <Typography variant="h4" sx={{ mb: 4, fontWeight: "bold", color: "#2c3e50", textAlign: "center" }}>
                    <ShoppingCart sx={{ verticalAlign: "middle", mr: 1 }} /> Passer Votre Commande
                </Typography>
                <Grid container spacing={4}>
                    {/* Client Information Section */}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ backgroundColor: "#fff", p: 3, borderRadius: 2, boxShadow: 1 }}>
                            <Typography variant="h6" sx={{ mb: 3, fontWeight: "bold", color: "#34495e" }}>
                                <Person sx={{ verticalAlign: "middle", mr: 1 }} /> Informations du Client
                            </Typography>
                            <TextField
                                fullWidth
                                label="Nom complet"
                                name="client.fullName"
                                value={formik.values.client.fullName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.client?.fullName && Boolean(formik.errors.client?.fullName)}
                                helperText={formik.touched.client?.fullName && formik.errors.client?.fullName}
                                sx={{ mb: 3 }}
                                InputProps={{
                                    startAdornment: <Person sx={{ color: "#7f8c8d", mr: 1 }} />,
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Numéro de téléphone"
                                name="client.phone"
                                value={formik.values.client.phone}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.client?.phone && Boolean(formik.errors.client?.phone)}
                                helperText={formik.touched.client?.phone && formik.errors.client?.phone}
                                sx={{ mb: 3 }}
                                InputProps={{
                                    startAdornment: <Phone sx={{ color: "#7f8c8d", mr: 1 }} />,
                                }}
                            />
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <InputLabel>Gouvernorat</InputLabel>
                                <Select
                                    value={activeGov.gov}
                                    onChange={(event) => filterSelectedGov(event.target.value)}
                                >
                                    {govs.map((gov) => (
                                        <MenuItem key={`gov-${gov.id}`} value={gov.id}>{gov.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ mb: 3 }}>
                                <InputLabel>Délégation</InputLabel>
                                <Select
                                    value={formik.values.client.deleg_id}
                                    name="client.deleg_id"
                                    onChange={formik.handleChange}
                                    error={formik.touched.client?.deleg_id && Boolean(formik.errors.client?.deleg_id)}
                                >
                                    {activeGov.delegations.map((delegation) => (
                                        <MenuItem key={`delegation-${delegation.id}`} value={delegation.id}>{delegation.name}</MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText error={formik.touched.client?.deleg_id && Boolean(formik.errors.client?.deleg_id)}>
                                    {formik.touched.client?.deleg_id && formik.errors.client?.deleg_id}
                                </FormHelperText>
                            </FormControl>
                            <TextField
                                fullWidth
                                label="Adresse"
                                name="client.address"
                                value={formik.values.client.address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.client?.address && Boolean(formik.errors.client?.address)}
                                helperText={formik.touched.client?.address && formik.errors.client?.address}
                                InputProps={{
                                    startAdornment: <LocationOn sx={{ color: "#7f8c8d", mr: 1 }} />,
                                }}
                            />
                        </Box>
                    </Grid>

                    {/* More Products Section */}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ backgroundColor: "#fff", p: 3, borderRadius: 2, boxShadow: 1 }}>
                            <Stack direction="column" spacing={2} alignItems="center" padding={2}>
                                <Typography variant="h6" sx={{ fontWeight: "bold", color: "#34495e" }}>
                                    Découvrez d'autres produits
                                </Typography>
                                <Typography variant="body2" sx={{ display: "flex", alignItems: "center", color: "#7f8c8d" }}>
                                    <Info sx={{ mr: 1, fontSize: "1rem" }} />
                                    Votre panier restera intact si vous naviguez vers d'autres produits.
                                </Typography>
                                <Container maxWidth="md">
                                    <Grid container spacing={2}>
                                        {products.map((product, index) => (
                                            <Grid item xs={12} sm={6} key={index}>
                                                <ProductCarousel product={product} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Container>
                            </Stack>
                        </Box>
                    </Grid>

                    {/* Cart Section */}
                    <Grid item xs={12}>
                        <Box sx={{ backgroundColor: "#fff", p: 3, borderRadius: 2, boxShadow: 1 }}>
                            <Typography variant="h6" sx={{ mb: 3, fontWeight: "bold", color: "#34495e" }}>
                                <ShoppingCart sx={{ verticalAlign: "middle", mr: 1 }} /> Votre Panier
                            </Typography>
                            {items.length > 0 ? (
                                items.map((item, index) => (
                                    <Box key={index} sx={{ mb: 2, p: 2, border: "1px solid #e0e0e0", borderRadius: 2 }}>
                                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                                            <Typography variant="subtitle1">{item.name}</Typography>
                                            <Stack direction="row" alignItems="center" spacing={1}>
                                                <IconButton onClick={() => handleQuantityChange(item.id, item.quantity - 1)} disabled={item.quantity === 1}>
                                                    <Remove />
                                                </IconButton>
                                                <Typography>{item.quantity}</Typography>
                                                <IconButton onClick={() => handleQuantityChange(item.id, item.quantity + 1)}>
                                                    <Add />
                                                </IconButton>
                                            </Stack>
                                            <Stack alignItems="flex-end">
                                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                                    {(getDiscountedPrice(item) * item.quantity).toFixed(2)} dt
                                                </Typography>
                                                <Typography sx={{ textDecoration: "line-through", color: "#e74c3c", fontSize: "0.875rem" }}>
                                                    {(item.price * item.quantity).toFixed(2)} dt
                                                </Typography>
                                            </Stack>
                                            <IconButton color="error" onClick={() => removeItem(item.id)}>✖</IconButton>
                                        </Stack>
                                    </Box>
                                ))
                            ) : (
                                <Typography color="error">Aucun produit dans le panier</Typography>
                            )}
                            <Stack spacing={2} sx={{ mt: 3 }}>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body1">Sous-total</Typography>
                                    <Typography variant="body1">{subtotal.toFixed(2)} dt</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="body1">
                                        <LocalShipping sx={{ verticalAlign: "middle", mr: 1 }} /> Livraison
                                    </Typography>
                                    <Typography variant="body1">{deliveryFee.toFixed(2)} dt</Typography>
                                </Stack>
                                <Stack direction="row" justifyContent="space-between">
                                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>Total</Typography>
                                    <Typography variant="h6" sx={{ fontWeight: "bold", color: "#27ae60" }}>{total.toFixed(2)} dt</Typography>
                                </Stack>
                                <Stack>
                                    <Typography>
                                        * Paiement a la livraison
                                    </Typography>
                                </Stack>
                            </Stack>

                            <Button
                                type="submit"
                                fullWidth
                                onClick={() => formik.handleSubmit()}
                                variant="contained"
                                size="large"
                                sx={{ mt: 3, py: 1.5, backgroundColor: "#27ae60", '&:hover': { backgroundColor: "#219653" } }}
                            >
                                Confirmer la Commande
                            </Button>
                        </Box>
                    </Grid>
                    <Grid container item xs={12} justifyContent="center">
                        <Grid container spacing={2} justifyContent="center">
                            <Typography variant="h6" sx={{fontWeight: "bold", color: "#27ae60"}}>
                                Contactez-nous :
                            </Typography>
                        </Grid>
                        <ContactIcons/>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}
import React from "react";
import { IconButton, Typography, Stack, Box, Button } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useCart } from "react-use-cart";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function ShowCart() {
    const { items, updateItemQuantity, removeItem, isEmpty } = useCart();
    const navigate = useNavigate();

    const handleQuantityChange = (itemId, newQuantity) => {
        updateItemQuantity(itemId, newQuantity);
    };

    // Calculate the discounted price for a single item
    const getDiscountedPrice = (product) => {
        if (!product.reduction || product.reduction.length === 0) return product.price;

        const applicableReduction = product.reduction
            .filter((reduction) => product.quantity >= reduction.quantity)
            .reduce((max, item) => Math.max(max, item.reduction), 0);

        return product.price - (applicableReduction / 100) * product.price;
    };

    // Calculate the total discount for all items in the cart
    const getTotalDiscount = () => {
        return items.reduce((acc, item) => {
            const originalPrice = item.price * item.quantity;
            const discountedPrice = getDiscountedPrice(item) * item.quantity;
            return acc + (originalPrice - discountedPrice);
        }, 0);
    };

    const deliveryFee = 8;
    const subtotal = items.reduce((acc, item) => acc + item.quantity * getDiscountedPrice(item), 0);
    const totalDiscount = getTotalDiscount();
    const total = subtotal + deliveryFee;

    // Handle checkout with tracking
    const handleCheckout = () => {
        if (!isEmpty) {
            navigate('/order');
        }
    };

    return (
        <Box sx={{ backgroundColor: "#f8f9fa", width: "100%", py: { xs: 1, sm: 2 } }}>
            <Box
                sx={{
                    width: '100%',
                    maxWidth: { xs: '100%', sm: '500px', md: '700px' }, // Adjusted for smaller screens
                    mx: "auto",
                    px: { xs: 1, sm: 2 },
                    boxSizing: 'border-box',
                }}
            >
                <Box sx={{ backgroundColor: "#fff", p: { xs: 1, sm: 2 }, borderRadius: 2, boxShadow: 2 }}>
                    <Typography
                        variant="h5"
                        sx={{
                            mb: { xs: 1, sm: 1.5 },
                            fontWeight: "bold",
                            color: "#2c3e50",
                            fontSize: { xs: '1rem', sm: '1.25rem' },
                            textAlign: 'center',
                        }}
                    >
                        Panier
                    </Typography>
                    {items.length > 0 ? (
                        items.map((item, index) => (
                            <Box
                                key={index}
                                sx={{
                                    mb: { xs: 1, sm: 1.5 },
                                    p: { xs: 1, sm: 1.5 },
                                    border: "1px solid #e0e0e0",
                                    borderRadius: 2,
                                    overflow: 'hidden',
                                }}
                            >
                                <Stack
                                    direction={{ xs: 'column' }} // Stack vertically on small screens
                                    alignItems={{ xs: 'flex-start', sm: 'center' }}
                                    justifyContent="space-between"
                                    spacing={{ xs: 0.5, sm: 1 }}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        sx={{
                                            fontWeight: "bold",
                                            fontSize: { xs: '0.85rem', sm: '1rem' },
                                            overflowWrap: 'break-word', // Allow multi-line wrapping
                                            whiteSpace: 'normal', // Enable multi-line
                                            maxWidth: '100%', // Prevent overflow
                                        }}
                                    >
                                        {item.name}
                                    </Typography>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={{ xs: 0.5, sm: 1 }}
                                        sx={{ mt: { xs: 0.5, sm: 0 } }} // Margin on small screens
                                    >
                                        <IconButton
                                            onClick={() => handleQuantityChange(item.id, item.quantity - 1)}
                                            disabled={item.quantity === 1}
                                            sx={{ color: "#2c3e50", p: { xs: 0.25, sm: 0.5 } }}
                                        >
                                            <Remove fontSize="small" />
                                        </IconButton>
                                        <Typography sx={{ fontSize: { xs: '0.75rem', sm: '0.9rem' } }}>
                                            {item.quantity}
                                        </Typography>
                                        <IconButton
                                            onClick={() => handleQuantityChange(item.id, item.quantity + 1)}
                                            sx={{ color: "#2c3e50", p: { xs: 0.25, sm: 0.5 } }}
                                        >
                                            <Add fontSize="small" />
                                        </IconButton>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                fontWeight: "bold",
                                                fontSize: { xs: '0.75rem', sm: '0.9rem' },
                                                minWidth: '60px',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {(getDiscountedPrice(item) * item.quantity).toFixed(3)} dt
                                        </Typography>
                                        <IconButton
                                            color="error"
                                            onClick={() => removeItem(item.id)}
                                            sx={{ color: "#e74c3c", p: { xs: 0.25, sm: 0.5 } }}
                                        >
                                            ✖
                                        </IconButton>
                                    </Stack>
                                </Stack>
                            </Box>
                        ))
                    ) : (
                        <Typography
                            color="error"
                            sx={{ fontSize: { xs: '0.85rem', sm: '1rem' }, textAlign: 'center' }}
                        >
                            Panier vide
                        </Typography>
                    )}
                    <Stack spacing={{ xs: 0.5, sm: 1 }} sx={{ mt: { xs: 1, sm: 1.5 } }}>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="h6" sx={{ fontSize: { xs: '0.85rem', sm: '1rem' } }}>
                                Sous-total
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: { xs: '0.85rem', sm: '1rem' } }}>
                                {subtotal.toFixed(3)} dt
                            </Typography>
                        </Stack>
                        {totalDiscount > 0 && (
                            <Stack direction="row" justifyContent="space-between">
                                <Typography variant="h6" sx={{ fontSize: { xs: '0.85rem', sm: '1rem' } }}>
                                    Réduction
                                </Typography>
                                <Typography variant="h6" sx={{ fontSize: { xs: '0.85rem', sm: '1rem' }, color: '#27ae60' }}>
                                    -{totalDiscount.toFixed(3)} dt
                                </Typography>
                            </Stack>
                        )}
                        <Stack direction="row" justifyContent="space-between">
                            <Typography variant="h6" sx={{ fontSize: { xs: '0.85rem', sm: '1rem' } }}>
                                Livraison
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: { xs: '0.85rem', sm: '1rem' } }}>
                                {deliveryFee.toFixed(3)} dt
                            </Typography>
                        </Stack>
                        <Stack direction="row" justifyContent="space-between">
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: "bold",
                                    color: "#27ae60",
                                    fontSize: { xs: '0.9rem', sm: '1.25rem' },
                                }}
                            >
                                Total
                            </Typography>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: "bold",
                                    color: "#27ae60",
                                    fontSize: { xs: '0.9rem', sm: '1.25rem' },
                                }}
                            >
                                {total.toFixed(3)} dt
                            </Typography>
                        </Stack>
                    </Stack>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={isEmpty}
                        fullWidth
                        sx={{
                            mt: { xs: 1, sm: 1.5 },
                            py: { xs: 0.5, sm: 0.75 },
                            backgroundColor: "#2c3e50",
                            '&:hover': { backgroundColor: "#34495e" },
                            fontSize: { xs: '0.8rem', sm: '0.9rem' },
                        }}
                        onClick={handleCheckout}
                    >
                        Passer la commande
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
import React, { useState } from 'react';
import { Box, Typography, Grid, FormControl, InputLabel, Select, MenuItem, List, ListItem, ListItemText } from '@mui/material';
import { styled } from '@mui/system';

// Styled component for the rank badge
const RankBadge = styled('span')(({ theme }) => ({
    backgroundColor: '#FFD700', // Gold color
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: '#fff',
    marginLeft: '10px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    [theme.breakpoints.down('sm')]: {
        width: '30px',
        height: '30px',
        fontSize: '1rem',
    },
}));

const TunisianRegionRank = () => {
    // List of Tunisian governorates (24 ولاية)
    const governorates = [
        'تونس', 'أريانة', 'بن عروس', 'منوبة', 'نابل', 'زغوان', 'باجة', 'جندوبة',
        'الكاف', 'سليانة', 'سوسة', 'المنستير', 'المهدية', 'صفاقس', 'القيروان',
        'القصرين', 'سيدي بوزيد', 'قابس', 'مدنين', 'تطاوين', 'قفصة', 'توزر',
        'قبلي', 'بنزرت'
    ];

    // 50 Common Tunisian first names (shared across all governorates)
    const firstNames = [
        'ياسين', 'أميرة', 'محمد', 'سارة', 'علي', 'نور', 'زينب', 'أحمد', 'لينا', 'كريم',
        'حسن', 'فاطمة', 'إيمان', 'خالد', 'ريم', 'سلمى', 'عمر', 'هدى', 'طارق', 'مريم',
        'يوسف', 'حنان', 'بسمة', 'إسلام', 'نادية', 'أمين', 'سناء', 'وليد', 'آية', 'مهدي',
        'جوهرة', 'حاتم', 'شيماء', 'بلال', 'رجاء', 'صابر', 'ليلى', 'سيف', 'عائشة', 'نبيل',
        'فرح', 'زياد', 'مروة', 'أسامة', 'حبيبة', 'رضا', 'إيناس', 'منير', 'سمية', 'عبد الرحمن'
    ];

    // 20 Last names per governorate (region-specific)
    const lastNamesByGovernorate = {
        'تونس': [
            'الطرابلسي', 'بن عبد الله', 'الغربي', 'التليلي', 'الناصري', 'بن صالح', 'القاسمي', 'الحاج', 'الفرجاني', 'المنصري',
            'الشابي', 'بن عمر', 'السعيدي', 'العبيدي', 'الزغبي', 'الخامس', 'بن يوسف', 'القروي', 'البجاوي', 'السنوسي'
        ],
        'أريانة': [
            'الصغير', 'بن صالح', 'القاسمي', 'الحاج', 'الفرجاني', 'المنصري', 'الشابي', 'الغربي', 'بن عبد الله', 'الطرابلسي',
            'التليلي', 'الناصري', 'بن علي', 'الجبالي', 'العلوي', 'التركي', 'بن عمر', 'السعيدي', 'العبيدي', 'الزغبي'
        ],
        'بن عروس': [
            'بن علي', 'الجبالي', 'العلوي', 'التركي', 'بن عمر', 'السعيدي', 'العبيدي', 'الزغبي', 'الخامس', 'بن يوسف',
            'القروي', 'البجاوي', 'السنوسي', 'المهذبي', 'الحديدي', 'الصغير', 'بن صالح', 'القاسمي', 'الحاج', 'الفرجاني'
        ],
        'منوبة': [
            'التركي', 'بن عمر', 'السعيدي', 'العبيدي', 'الزغبي', 'الخامس', 'بن يوسف', 'القروي', 'البجاوي', 'السنوسي',
            'المهذبي', 'الحديدي', 'الصغير', 'بن صالح', 'القاسمي', 'الحاج', 'الفرجاني', 'المنصري', 'الشابي', 'الغربي'
        ],
        'نابل': [
            'الخامس', 'بن يوسف', 'القروي', 'البجاوي', 'السنوسي', 'المهذبي', 'الحديدي', 'الصغير', 'بن صالح', 'القاسمي',
            'الحاج', 'الفرجاني', 'المنصري', 'الشابي', 'الغربي', 'بن عبد الله', 'الطرابلسي', 'التليلي', 'الناصري', 'بن علي'
        ],
        'زغوان': [
            'المهذبي', 'الحديدي', 'الصغير', 'بن صالح', 'القاسمي', 'الحاج', 'الفرجاني', 'المنصري', 'الشابي', 'الغربي',
            'بن عبد الله', 'الطرابلسي', 'التليلي', 'الناصري', 'بن علي', 'الجبالي', 'العلوي', 'التركي', 'بن عمر', 'السعيدي'
        ],
        'باجة': [
            'الفرجاني', 'المنصري', 'الشابي', 'الغربي', 'بن عبد الله', 'الطرابلسي', 'التليلي', 'الناصري', 'بن علي', 'الجبالي',
            'العلوي', 'التركي', 'بن عمر', 'السعيدي', 'العبيدي', 'الزغبي', 'الخامس', 'بن يوسف', 'القروي', 'البجاوي'
        ],
        'جندوبة': [
            'الطرابلسي', 'التليلي', 'الناصري', 'بن علي', 'الجبالي', 'العلوي', 'التركي', 'بن عمر', 'السعيدي', 'العبيدي',
            'الزغبي', 'الخامس', 'بن يوسف', 'القروي', 'البجاوي', 'السنوسي', 'المهذبي', 'الحديدي', 'الصغير', 'بن صالح'
        ],
        'الكاف': [
            'العلوي', 'التركي', 'بن عمر', 'السعيدي', 'العبيدي', 'الزغبي', 'الخامس', 'بن يوسف', 'القروي', 'البجاوي',
            'السنوسي', 'المهذبي', 'الحديدي', 'الصغير', 'بن صالح', 'القاسمي', 'الحاج', 'الفرجاني', 'المنصري', 'الشابي'
        ],
        'سليانة': [
            'الزغبي', 'الخامس', 'بن يوسف', 'القروي', 'البجاوي', 'السنوسي', 'المهذبي', 'الحديدي', 'الصغير', 'بن صالح',
            'القاسمي', 'الحاج', 'الفرجاني', 'المنصري', 'الشابي', 'الغربي', 'بن عبد الله', 'الطرابلسي', 'التليلي', 'الناصري'
        ],
        'سوسة': [
            'السنوسي', 'المهذبي', 'الحديدي', 'الصغير', 'بن صالح', 'القاسمي', 'الحاج', 'الفرجاني', 'المنصري', 'الشابي',
            'الغربي', 'بن عبد الله', 'الطرابلسي', 'التليلي', 'الناصري', 'بن علي', 'الجبالي', 'العلوي', 'التركي', 'بن عمر'
        ],
        'المنستير': [
            'القاسمي', 'الحاج', 'الفرجاني', 'المنصري', 'الشابي', 'الغربي', 'بن عبد الله', 'الطرابلسي', 'التليلي', 'الناصري',
            'بن علي', 'الجبالي', 'العلوي', 'التركي', 'بن عمر', 'السعيدي', 'العبيدي', 'الزغبي', 'الخامس', 'بن يوسف'
        ],
        'المهدية': [
            'الغربي', 'بن عبد الله', 'الطرابلسي', 'التليلي', 'الناصري', 'بن علي', 'الجبالي', 'العلوي', 'التركي', 'بن عمر',
            'السعيدي', 'العبيدي', 'الزغبي', 'الخامس', 'بن يوسف', 'القروي', 'البجاوي', 'السنوسي', 'المهذبي', 'الحديدي'
        ],
        'صفاقس': [
            'بن علي', 'الجبالي', 'العلوي', 'التركي', 'بن عمر', 'السعيدي', 'العبيدي', 'الزغبي', 'الخامس', 'بن يوسف',
            'القروي', 'البجاوي', 'السنوسي', 'المهذبي', 'الحديدي', 'الصغير', 'بن صالح', 'القاسمي', 'الحاج', 'الفرجاني'
        ],
        'القيروان': [
            'السعيدي', 'العبيدي', 'الزغبي', 'الخامس', 'بن يوسف', 'القروي', 'البجاوي', 'السنوسي', 'المهذبي', 'الحديدي',
            'الصغير', 'بن صالح', 'القاسمي', 'الحاج', 'الفرجاني', 'المنصري', 'الشابي', 'الغربي', 'بن عبد الله', 'الطرابلسي'
        ],
        'القصرين': [
            'القروي', 'البجاوي', 'السنوسي', 'المهذبي', 'الحديدي', 'الصغير', 'بن صالح', 'القاسمي', 'الحاج', 'الفرجاني',
            'المنصري', 'الشابي', 'الغربي', 'بن عبد الله', 'الطرابلسي', 'التليلي', 'الناصري', 'بن علي', 'الجبالي', 'العلوي'
        ],
        'سيدي بوزيد': [
            'الصغير', 'بن صالح', 'القاسمي', 'الحاج', 'الفرجاني', 'المنصري', 'الشابي', 'الغربي', 'بن عبد الله', 'الطرابلسي',
            'التليلي', 'الناصري', 'بن علي', 'الجبالي', 'العلوي', 'التركي', 'بن عمر', 'السعيدي', 'العبيدي', 'الزغبي'
        ],
        'قابس': [
            'المنصري', 'الشابي', 'الغربي', 'بن عبد الله', 'الطرابلسي', 'التليلي', 'الناصري', 'بن علي', 'الجبالي', 'العلوي',
            'التركي', 'بن عمر', 'السعيدي', 'العبيدي', 'الزغبي', 'الخامس', 'بن يوسف', 'القروي', 'البجاوي', 'السنوسي'
        ],
        'مدنين': [
            'التليلي', 'الناصري', 'بن علي', 'الجبالي', 'العلوي', 'التركي', 'بن عمر', 'السعيدي', 'العبيدي', 'الزغبي',
            'الخامس', 'بن يوسف', 'القروي', 'البجاوي', 'السنوسي', 'المهذبي', 'الحديدي', 'الصغير', 'بن صالح', 'القاسمي'
        ],
        'تطاوين': [
            'التركي', 'بن عمر', 'السعيدي', 'العبيدي', 'الزغبي', 'الخامس', 'بن يوسف', 'القروي', 'البجاوي', 'السنوسي',
            'المهذبي', 'الحديدي', 'الصغير', 'بن صالح', 'القاسمي', 'الحاج', 'الفرجاني', 'المنصري', 'الشابي', 'الغربي'
        ],
        'قفصة': [
            'الخامس', 'بن يوسف', 'القروي', 'البجاوي', 'السنوسي', 'المهذبي', 'الحديدي', 'الصغير', 'بن صالح', 'القاسمي',
            'الحاج', 'الفرجاني', 'المنصري', 'الشابي', 'الغربي', 'بن عبد الله', 'الطرابلسي', 'التليلي', 'الناصري', 'بن علي'
        ],
        'توزر': [
            'المهذبي', 'الحديدي', 'الصغير', 'بن صالح', 'القاسمي', 'الحاج', 'الفرجاني', 'المنصري', 'الشابي', 'الغربي',
            'بن عبد الله', 'الطرابلسي', 'التليلي', 'الناصري', 'بن علي', 'الجبالي', 'العلوي', 'التركي', 'بن عمر', 'السعيدي'
        ],
        'قبلي': [
            'الحاج', 'الفرجاني', 'المنصري', 'الشابي', 'الغربي', 'بن عبد الله', 'الطرابلسي', 'التليلي', 'الناصري', 'بن علي',
            'الجبالي', 'العلوي', 'التركي', 'بن عمر', 'السعيدي', 'العبيدي', 'الزغبي', 'الخامس', 'بن يوسف', 'القروي'
        ],
        'بنزرت': [
            'بن عبد الله', 'الطرابلسي', 'التليلي', 'الناصري', 'بن علي', 'الجبالي', 'العلوي', 'التركي', 'بن عمر', 'السعيدي',
            'العبيدي', 'الزغبي', 'الخامس', 'بن يوسف', 'القروي', 'البجاوي', 'السنوسي', 'المهذبي', 'الحديدي', 'الصغير'
        ],
    };

    // Generate 15 unique kids per governorate with appropriate last names
    const getKidsForGovernorate = (gov) => {
        const lastNames = lastNamesByGovernorate[gov];
        const kids = [];
        const usedNames = new Set();

        while (kids.length < 15) {
            const first = firstNames[Math.floor(Math.random() * firstNames.length)];
            const last = lastNames[Math.floor(Math.random() * lastNames.length)];
            const fullName = `${first} ${last}`;

            if (!usedNames.has(fullName)) {
                usedNames.add(fullName);
                kids.push({ name: fullName, rank: kids.length + 1 });
            }
        }
        return kids;
    };

    const kidsByGovernorate = governorates.reduce((acc, gov) => {
        acc[gov] = getKidsForGovernorate(gov);
        return acc;
    }, {});

    const [selectedGovernorate, setSelectedGovernorate] = useState(governorates[0]); // Default: تونس
    const [kidsList, setKidsList] = useState(kidsByGovernorate[governorates[0]]);

    const handleGovernorateChange = (event) => {
        const newGovernorate = event.target.value;
        setSelectedGovernorate(newGovernorate);
        setKidsList(kidsByGovernorate[newGovernorate]); // Update kids list
    };

    return (
        <Box
            sx={{
                mt: 8,
                textAlign: 'center',
                px: { xs: 2, sm: 0 }, // Responsive padding
                background: 'linear-gradient(135deg, #87CEEB, #FFB6C1)', // Fun gradient
                borderRadius: '16px',
                py: 4,
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    fontWeight: 600,
                    mb: 2,
                    color: 'primary.dark',
                    fontSize: { xs: '1.5rem', sm: '2rem' }, // Responsive font
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.2)',
                }}
            >
                مرحبًا بكم في ولاية {selectedGovernorate}!
            </Typography>
            <Typography
                variant="h6"
                sx={{
                    fontWeight: 600,
                    mb: 4,
                    color: 'secondary.main',
                    fontSize: { xs: '1.2rem', sm: '1.5rem' },
                }}
            >
                ترتيب الأطفال في الولاية
            </Typography>

            {/* Governorate Select */}
            <FormControl sx={{ mb: 4, minWidth: 200 }}>
                <InputLabel id="governorate-label">اختر ولاية</InputLabel>
                <Select
                    labelId="governorate-label"
                    value={selectedGovernorate}
                    label="اختر ولاية"
                    onChange={handleGovernorateChange}
                    sx={{ backgroundColor: '#fff', borderRadius: '8px' }}
                >
                    {governorates.map((gov) => (
                        <MenuItem key={gov} value={gov}>
                            {gov}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Grid container justifyContent="center">
                <Grid item xs={12} sm={8} md={6}>
                    <List sx={{ bgcolor: '#fff', borderRadius: '8px', p: 2 }}>
                        {kidsList.map((kid) => (
                            <ListItem key={kid.rank} sx={{ py: 1 }}>
                                <RankBadge>{kid.rank}</RankBadge>
                                <ListItemText
                                    primary={kid.name}
                                    primaryTypographyProps={{
                                        fontSize: { xs: '1rem', sm: '1.2rem' },
                                        fontWeight: 500,
                                        color: 'primary.dark',
                                    }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TunisianRegionRank;
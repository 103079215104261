import React, { useState, useEffect, useRef } from 'react';
import { Button, Typography, Box, Container, Paper, IconButton } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { CheckCircle, Cancel, PlayArrow } from '@mui/icons-material';
import arabicLetters from '../../data/arabicLetters.json';
import frenchLetters from '../../data/frenchLetters.json';
import englishLetters from '../../data/englishLetters.json';

// Language data mapping
const languageData = {
    arabic: arabicLetters,
    french: frenchLetters,
    english: englishLetters,
};

// Localized text for different languages, including instructions
const localizedText = {
    arabic: {
        title: 'لعبة مطابقة الحروف',
        score: 'النقاط',
        correct: 'صحيح!',
        wrong: 'حاول مرة أخرى',
        instructions: 'اختر الصورة التي تبدأ بالحرف الموجود! اضغط على زر الصوت لتسمعه.',
    },
    french: {
        title: 'Jeu de correspondance des lettres',
        score: 'Points',
        correct: 'Correct!',
        wrong: 'Réessaye',
        instructions: 'Choisis l’image qui commence par la lettre affichée ! Clique sur le bouton pour écouter.',
    },
    english: {
        title: 'Letter Matching Game',
        score: 'Score',
        correct: 'Correct!',
        wrong: 'Try Again',
        instructions: 'Pick the picture that starts with the letter shown! Press the sound button to hear it.',
    },
};

const LetterMatchingGame = () => {
    const { language, letter } = useParams();
    const currentLanguage = languageData[language] || englishLetters;
    const text = localizedText[language] || localizedText.english;

    const [gameState, setGameState] = useState({
        score: 0,
        currentLetter: letter,
        correctOption: null,
        options: [],
        feedback: null,
    });

    const audioRef = useRef(null);

    useEffect(() => {
        initializeGame();
    }, [letter]);

    const initializeGame = () => {
        const letters = currentLanguage.letters;
        const specificLetterData = letters.find(
            (l) => l.letter.toLowerCase() === letter.toLowerCase()
        );

        if (!specificLetterData) {
            console.error(`Letter ${letter} not found in ${language} data.`);
            return;
        }

        const correctWord =
            specificLetterData.correctWords[
                Math.floor(Math.random() * specificLetterData.correctWords.length)
                ];

        const distractors = letters
            .filter((l) => l.letter.toLowerCase() !== letter.toLowerCase())
            .sort(() => Math.random() - 0.5)
            .slice(0, 2)
            .map((l) => l.correctWords[0]);

        const options = [correctWord, ...distractors].sort(() => Math.random() - 0.5);

        setGameState((prev) => ({
            ...prev,
            correctOption: correctWord,
            options: options,
            feedback: null,
        }));
    };

    const playAudio = (audioSrc) => {
        if (!audioSrc) {
            console.error('No audio source provided');
            return;
        }
        if (audioRef.current) {
            audioRef.current.pause();
        }
        audioRef.current = new Audio(audioSrc);
        audioRef.current.play().catch((error) => {
            console.error('Error playing audio:', error);
        });
    };

    const handleAnswer = (selectedOption) => {
        const isCorrect = selectedOption === gameState.correctOption;
        let newScore = gameState.score;

        if (isCorrect) {
            newScore += 1;
            setGameState((prev) => ({
                ...prev,
                score: newScore,
                feedback: 'correct',
            }));
            setTimeout(initializeGame, 1500);
        } else {
            setGameState((prev) => ({
                ...prev,
                feedback: 'wrong',
            }));
            setTimeout(initializeGame, 1500);
        }
    };

    return (
        <Container
            sx={{
                direction: language === 'arabic' ? 'rtl' : 'ltr',
                background: 'linear-gradient(135deg, #6B7280 0%, #111827 100%)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: { xs: 2, sm: 4 },
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    maxWidth: { xs: '100%', sm: 800 },
                    borderRadius: '20px',
                    background: '#1F2937',
                    border: '2px solid #4B5563',
                    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.3)',
                    overflow: 'hidden',
                }}
            >
                <Paper
                    elevation={0}
                    sx={{
                        p: { xs: 2, sm: 4 },
                        background: 'transparent',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                    }}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            textAlign: 'center',
                            color: '#F3F4F6',
                            fontFamily: "'Roboto', sans-serif",
                            fontSize: { xs: '1.5rem', sm: '2rem' },
                        }}
                    >
                        {text.title}
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{
                            textAlign: 'center',
                            color: '#FBBF24', // Bright yellow for kid-friendly appeal
                            fontFamily: "'Roboto', sans-serif",
                            fontSize: { xs: '1rem', sm: '1.25rem' },
                            px: { xs: 1, sm: 2 },
                            lineHeight: 1.5,
                        }}
                    >
                        {text.instructions}
                    </Typography>

                    <Paper
                        sx={{
                            px: 2,
                            py: 1,
                            borderRadius: '15px',
                            background: '#4B5563',
                            color: '#F3F4F6',
                            textAlign: 'center',
                            width: 'fit-content',
                            mx: 'auto',
                        }}
                    >
                        <Typography variant="h6">
                            {text.score}: <strong>{gameState.score}</strong>
                        </Typography>
                    </Paper>

                    <Typography
                        variant="h3"
                        sx={{
                            textAlign: 'center',
                            color: '#60A5FA',
                            fontSize: { xs: '2rem', sm: '3rem' },
                        }}
                    >
                        {gameState.currentLetter}
                    </Typography>

                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: {
                                xs: 'repeat(1, 1fr)',
                                sm: 'repeat(2, 1fr)',
                                md: 'repeat(3, 1fr)',
                            },
                            gap: { xs: 2, sm: 3 },
                            mt: 2,
                        }}
                    >
                        {gameState.options.map((option, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={() => {
                                        if (!gameState.feedback) {
                                            handleAnswer(option);
                                        }
                                    }}
                                    sx={{
                                        position: 'relative',
                                        backgroundColor: '#374151',
                                        color: '#fff',
                                        borderRadius: '15px',
                                        p: 0,
                                        overflow: 'hidden',
                                        '&:hover': { backgroundColor: '#4B5563' },
                                    }}
                                >
                                    <img
                                        src={option.image}
                                        alt={option.word}
                                        style={{
                                            width: '100%',
                                            height: '150px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                    {option.sound && (
                                        <IconButton
                                            size="small"
                                            sx={{
                                                position: 'absolute',
                                                top: 8,
                                                right: 8,
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                color: '#fff',
                                                '&:hover': {
                                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                                },
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                playAudio(option.sound);
                                            }}
                                            aria-label={`Play audio for ${option.word}`}
                                        >
                                            <PlayArrow fontSize="small" />
                                        </IconButton>
                                    )}
                                </Button>
                            </motion.div>
                        ))}
                    </Box>

                    <AnimatePresence>
                        {gameState.feedback && (
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0 }}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    textAlign: 'center',
                                    backgroundColor: 'rgba(31, 41, 55, 0.9)',
                                    borderRadius: '15px',
                                    p: 2,
                                }}
                            >
                                {gameState.feedback === 'correct' ? (
                                    <CheckCircle sx={{ fontSize: { xs: 60, sm: 100 }, color: '#34D399' }} />
                                ) : (
                                    <Cancel sx={{ fontSize: { xs: 60, sm: 100 }, color: '#F87171' }} />
                                )}
                                <Typography
                                    variant="h5"
                                    sx={{
                                        mt: 1,
                                        color: gameState.feedback === 'correct' ? '#34D399' : '#F87171',
                                        fontSize: { xs: '1rem', sm: '1.5rem' },
                                    }}
                                >
                                    {gameState.feedback === 'correct' ? text.correct : text.wrong}
                                </Typography>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </Paper>
            </Box>
        </Container>
    );
};

export default LetterMatchingGame;
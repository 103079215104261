import React from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import { useCart } from 'react-use-cart';
import { styled } from '@mui/system';
import { useDispatch } from 'react-redux';
import { openCart, toggleCart } from "../../store/slices/commonConfigSlice";
import CartModal from "../../common/CartModal";

const RtlBar = styled(Box)({
    direction: 'rtl', // Right-to-left direction
});

const FixedBottomComponent = ({ themes }) => {
    const { totalItems, cartTotal, addItem, items } = useCart(); // Use react-use-cart hooks
    const dispatch = useDispatch(); // Hook for dispatching actions

    const allSelected = items.length === themes.length; // Vérifie si tous les thèmes sont dans le panier

    // Calcul du total avant et après remise
    const totalBeforeRemise = themes.reduce((acc, theme) => acc + parseInt(theme.price), 0);
    const totalAfterRemise = allSelected ? totalBeforeRemise * 0.9 : totalBeforeRemise; // Appliquer 10% si tous sont sélectionnés

    const handlePlaceOrder = () => {
        dispatch(toggleCart()); // Ouvrir/fermer le panier
    };

    const handleBuyAllThemes = () => {
        const cartItems = items.map(item => item.id);

        themes.forEach((product) => {
            if (!cartItems.includes(product.id)) {
                addItem({
                    id: product.id,
                    price: parseInt(product.price) * 0.9, // Appliquer 10% de réduction par défaut
                    name: product.name,
                    quantity: 1
                });
            }
        });

        dispatch(openCart()); // Ouvrir le panier après l'ajout
    };

    return (
        <>
            {/* Cart Modal */}
            <CartModal />

            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: '#fff',
                    padding: '10px',
                    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)',
                    zIndex: 1000,
                }}
            >
                {/* RTL Bar */}
                <RtlBar
                    sx={{
                        marginBottom: '10px',
                        padding: '10px',
                        backgroundColor: '#f7f7f7',
                        borderRadius: '4px',
                    }}
                >
                    <Grid container alignItems="center">
                        <Grid item xs={8}>
                            <Typography variant="body1">
                                لقد قمت بإختيار {totalItems} محاور من لعبة الذاكرة
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography variant="h6" align="right">
                                المجموع : {cartTotal.toFixed(3)} د
                            </Typography>
                        </Grid>
                    </Grid>
                </RtlBar>

                {/* Bottom Buttons */}
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={handlePlaceOrder}
                            sx={{ height: '56px' }}
                        >
                            Passer la commande
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={handleBuyAllThemes}
                            sx={{ height: '56px' }}
                        >
                            Acheter tous les thèmes ({totalAfterRemise.toFixed(3)} dt {allSelected ? "après remise 10%" : ""})
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default FixedBottomComponent;

// store/productsSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Base URL
const API_BASE_URL = 'https://www.api.sghiri.tn';

// Fetch all products
export const fetchAllProducts = createAsyncThunk(
    'products/fetchAllProducts',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/front/products/all`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to fetch all products');
        }
    }
);

// Fetch product sales
export const fetchProductsSales = createAsyncThunk(
    'products/fetchProductsSales',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/front/products/products_sales`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to fetch product sales');
        }
    }
);

// Fetch a single product by ID
export const fetchSingleProduct = createAsyncThunk(
    'products/fetchSingleProduct',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/front/products/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to fetch single product');
        }
    }
);

// Fetch related products by ID
export const fetchRelatedProducts = createAsyncThunk(
    'products/fetchRelatedProducts',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${API_BASE_URL}/front/products/related/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data || 'Failed to fetch related products');
        }
    }
);

const productsSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],           // All products
        productSales: [],       // Product sales data
        singleProduct: null,    // Single product details
        relatedProducts: [],    // Related products
        status: 'idle',         // General status
        error: null,            // General error
    },
    reducers: {
        // Reset state if needed
        resetProductsState: (state) => {
            state.products = [];
            state.productSales = [];
            state.singleProduct = null;
            state.relatedProducts = [];
            state.status = 'idle';
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        // Fetch All Products
        builder
            .addCase(fetchAllProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAllProducts.fulfilled, (state, action) => {
                state.products = action.payload;
                state.status = 'succeeded';
                state.error = null;
            })
            .addCase(fetchAllProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Fetch Product Sales
            .addCase(fetchProductsSales.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProductsSales.fulfilled, (state, action) => {
                state.productSales = action.payload;
                state.status = 'succeeded';
                state.error = null;
            })
            .addCase(fetchProductsSales.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Fetch Single Product
            .addCase(fetchSingleProduct.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSingleProduct.fulfilled, (state, action) => {
                state.singleProduct = action.payload;
                state.status = 'succeeded';
                state.error = null;
            })
            .addCase(fetchSingleProduct.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })

            // Fetch Related Products
            .addCase(fetchRelatedProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchRelatedProducts.fulfilled, (state, action) => {
                state.relatedProducts = action.payload;
                state.status = 'succeeded';
                state.error = null;
            })
            .addCase(fetchRelatedProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { resetProductsState } = productsSlice.actions;
export default productsSlice.reducer;
import logo from './logo.svg';
import './App.css';
import { Grid } from "@mui/material";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Routes } from "react-router-dom";
import Layout from "./Layout/Layout";
import Home from "./pages/Home";
import { ThemeProvider } from "@emotion/react";
import { theme as muiTheme } from "./theme/theme";
import React from "react";
import { themes } from "./json/memory_game/themes";
import MemoryCard from "./Component/card";
import MemoryGame from "./pages/MemoryGame";
import { CartProvider } from "react-use-cart";
import { Provider } from "react-redux";
import PlaceOrder from "./pages/PlaceOrder";
import Calendar2025 from "./pages/calendar2025";
import ConfirmOrder from "./common/confitmOrder";
import UnderConstruction from "./common/UnderConstruction";
import Calendar2025Price from "./pages/calendar2025Price";
import { Helmet } from "react-helmet";
import RamadanNoteBook from "./pages/RamadanNoteBook";
import HomePageMosaic from "./pages/Home";
import Phrazi from "./pages/Phrazi";
import store from "./store";
import Letters from "./pages/Letters";
import LettersGame from "./pages/LettersGame";
import LetterBasicComponent from "./pages/LetterBasicComponent";
import WriteErase from "./pages/WriteErase";


function App() {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<Layout />}>

                <Route path="/" element={<HomePageMosaic />} />
                <Route path="/memory-game" element={<MemoryGame />} />
                <Route path="/calendrier-2025" element={<Calendar2025 />} />
                <Route path="/calendrier" element={<Calendar2025Price />} />
                <Route path="/phrazi" element={<Phrazi />} />
                <Route path="/letters-game">
                    <Route path=":language">
                        <Route index element={<LettersGame />} />
                        <Route path=":letter" element={<LetterBasicComponent />} />
                    </Route>
                </Route>
                <Route path="/ramadhan-notebook" element={<RamadanNoteBook />} />
                <Route path="/write-and-erase" element={<WriteErase />} />
                <Route path="/order" element={<PlaceOrder />} />
                <Route path="/confirm-order/:orderId" element={<ConfirmOrder />} />
                {/* Dynamically map the themes */}
                {themes.map((theme) => (
                    // Parent route for each theme
                    <Route key={theme.slug} path={`/memory-game/${theme.slug}`} >

                        {/* Dynamically map the cards of each theme */}
                        {theme.cards && theme.cards.map((card) => (
                            <Route
                                key={card.name}
                                path={`/memory-game/${theme.slug}/${card.slug}`}
                                element={<MemoryCard themeData={theme} cardData={card} />}
                            />
                        ))}

                    </Route>
                ))}

            </Route>
        )
    )
    return (
        <ThemeProvider theme={muiTheme}>
            <Provider store={store}>
                <CartProvider>
                    <RouterProvider router={router} />
                </CartProvider>
            </Provider>
        </ThemeProvider>
    );
}

export default App;

import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useCart } from 'react-use-cart';
import ShowCart from './common/ShowCart';
import axios from "axios";

const AddProductToCard = ({ product, reductionPattern }) => {
    const { addItem, removeItem, getItem, items } = useCart();
    const cartItem = product ? getItem(product.id) : null;

    // Calculate the discounted price based on quantity (default to 1 if not in cart)
    const getDiscountedPrice = (price, qty) => {
        if (!reductionPattern || reductionPattern.length === 0) return price;
        const applicableReduction = reductionPattern
            .filter((reduction) => qty >= reduction.quantity)
            .reduce((max, item) => Math.max(max, item.reduction), 0);
        return price - (price * applicableReduction) / 100;
    };

    // Get the current discount message based on quantity
    const getDiscountMessage = (qty) => {
        if (!reductionPattern || reductionPattern.length === 0) return { message: '' };
        const applicableReduction = reductionPattern
            .filter((reduction) => qty >= reduction.quantity)
            .reduce((max, item) => Math.max(max, item.reduction), 0);
        return applicableReduction > 0
            ? { message: `Vous bénéficiez de ${applicableReduction}% de réduction!` }
            : { message: '' };
    };

    // Get the next discount message based on quantity
    const getNextDiscountMessage = (qty) => {
        if (!reductionPattern || reductionPattern.length === 0) return '';
        const nextDiscount = reductionPattern.find((reduction) => qty < reduction.quantity);
        return nextDiscount
            ? `Ajoutez ${nextDiscount.quantity - qty} de plus pour ${nextDiscount.reduction}% de réduction!`
            : '';
    };

    // Handle adding to cart
    const trackEvent = async (eventName, customData) => {
        try {
            const response = await axios.post('https://sghiriwebserver.onrender.com/track-event', {
                eventName,
                customData,
                clientIp: window.location.hostname === 'localhost' ? '127.0.0.1' : null,
                userAgent: navigator.userAgent,
            });
            console.log('Event tracked:', response.data);
        } catch (error) {
            console.error('Error tracking event:', error);
        }
    };

    const handleAddToCart = () => {
        const discountedPrice = getDiscountedPrice(product.price, 1);
        addItem({
            ...product,
            price: discountedPrice,
            reduction: reductionPattern || [] // Default to empty array if null
        }, 1);
        trackEvent('AddToCart', {
            value: discountedPrice,
            currency: 'TND',
            content_name: product.name,
            content_ids: [product.id],
            content_type: 'product',
        });
    };

    // Handle removing from cart
    const handleRemoveFromCart = () => {
        removeItem(product.id);
    };

    // Get current quantity from cart for discount messages
    const quantity = cartItem ? cartItem.quantity : 0;

    return (
        <Box
            sx={{
                p: { xs: 1.5, sm: 2 }, // Reduced padding on smaller screens
                border: '1px solid #ddd',
                borderRadius: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                boxShadow: 2, // Slightly reduced shadow for less visual weight
                bgcolor: 'white',
                width: '100%',
                maxWidth: { xs: '100%', sm: '350px', md: '400px' }, // Adjusted maxWidth for responsiveness
                mx: 'auto',
                boxSizing: 'border-box',
            }}
        >
            <Typography
                variant="h6"
                sx={{
                    mb: 1.5,
                    fontSize: { xs: '1.1rem', sm: '1.25rem' }, // Smaller font on mobile
                    textAlign: 'center',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word', // Ensure long words wrap
                }}
            >
                {product ? product.name : ''}
            </Typography>

            {/* Price Display */}
            <Typography
                variant="body1"
                sx={{
                    mb: 1.5,
                    fontSize: { xs: '0.9rem', sm: '1rem' }, // Adjusted font size
                    textAlign: 'center',
                    whiteSpace: 'normal', // Allow text to wrap naturally
                }}
            >
                {product && quantity > 0 ? (
                    <>
                        Prix: <strong>{getDiscountedPrice(product.price, quantity).toFixed(0)} DT</strong>
                        {getDiscountMessage(quantity).message && (
                            <Typography component="span" sx={{ color: '#00796b', ml: 1, fontSize: 'inherit' }}>
                                ({getDiscountMessage(quantity).message})
                            </Typography>
                        )}
                    </>
                ) : product ? (
                    <>
                        Prix: <strong>{product.price} DT</strong>
                    </>
                ) : (
                    ''
                )}
            </Typography>

            {/* Next Discount Message */}
            {reductionPattern && reductionPattern.length > 0 && getNextDiscountMessage(quantity) && (
                <Box
                    sx={{
                        mb: 1.5,
                        p: 0.75, // Reduced padding
                        bgcolor: '#fff3e0',
                        color: '#e65100',
                        borderRadius: 1,
                        fontSize: { xs: '0.75rem', sm: '0.85rem' }, // Smaller font
                        fontWeight: 'bold',
                        textAlign: 'center',
                        width: '100%',
                        maxWidth: '90%', // Slightly narrower to fit small screens
                        boxSizing: 'border-box',
                        overflowWrap: 'break-word', // Ensure text wraps
                    }}
                >
                    {getNextDiscountMessage(quantity)}
                </Box>
            )}

            {/* Current Discount Message */}
            {reductionPattern && reductionPattern.length > 0 && getDiscountMessage(quantity).message && (
                <Box
                    sx={{
                        mb: 1.5,
                        p: 0.75, // Reduced padding
                        bgcolor: '#e0f7fa',
                        color: '#00796b',
                        borderRadius: 1,
                        fontSize: { xs: '0.75rem', sm: '0.85rem' }, // Smaller font
                        fontWeight: 'bold',
                        textAlign: 'center',
                        width: '100%',
                        maxWidth: '90%', // Slightly narrower
                        boxSizing: 'border-box',
                        overflowWrap: 'break-word', // Ensure text wraps
                    }}
                >
                    {getDiscountMessage(quantity).message}
                </Box>
            )}

            {/* Add or Remove Button */}
            {product && cartItem ? (
                <Button
                    variant="outlined"
                    color="error"
                    onClick={handleRemoveFromCart}
                    sx={{
                        mb: 1.5,
                        py: 0.5, // Reduced padding
                        px: { xs: 1.5, sm: 3 }, // Adjusted horizontal padding
                        borderRadius: '25px', // Slightly smaller radius
                        fontSize: { xs: '0.8rem', sm: '0.9rem' }, // Smaller font
                        minWidth: '120px', // Ensure button doesn't shrink too much
                    }}
                >
                    Retirer du panier
                </Button>
            ) : product ? (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddToCart}
                    sx={{
                        mb: 1.5,
                        py: 0.5, // Reduced padding
                        px: { xs: 1.5, sm: 3 }, // Adjusted horizontal padding
                        borderRadius: '25px', // Slightly smaller radius
                        fontSize: { xs: '0.8rem', sm: '0.9rem' }, // Smaller font
                        minWidth: '120px', // Ensure button doesn't shrink too much
                    }}
                >
                    Ajouter au panier
                </Button>
            ) : null}

            {/* ShowCart */}
            <Box
                sx={{
                    width: '100%',
                    boxSizing: 'border-box',
                }}
            >
                <ShowCart />
            </Box>
        </Box>
    );
};

export default AddProductToCard;
import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { motion } from 'framer-motion';

const LetterExamples = ({ letter, examples, language }) => {
    const highlightLetter = (word) => {
        // Define letter variations only for Arabic
        const letterVariations = {
            'أ': ['أ', 'ا', 'إ', 'آ'],
            // Add more Arabic letter variations as needed
        };

        // Check for variants only if the language is Arabic
        if (language === 'arabic') {
            const variations = letterVariations[letter] || [letter];
            let index = -1;
            let matchedLetter = '';
            for (const variant of variations) {
                index = word.indexOf(variant);
                if (index !== -1) {
                    matchedLetter = variant;
                    break;
                }
            }

            if (index === -1) return word;

            return (
                <>
                    {word.substring(0, index)}
                    <span style={{ color: 'red' }}>{matchedLetter}</span>
                    {word.substring(index + 1)}
                </>
            );
        }

        // For non-Arabic languages, highlight the exact letter
        const index = word.toLowerCase().indexOf(letter.toLowerCase());
        if (index === -1) return word;

        return (
            <>
                {word.substring(0, index)}
                <span style={{ color: 'red' }}>{word[index]}</span>
                {word.substring(index + 1)}
            </>
        );
    };

    return (
        <Box sx={{ textAlign: 'center', p: 4 }}>
            {/* Main Letter */}
            <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
            >
                <Typography variant="h1" sx={{
                    color: 'red',
                    fontSize: '8rem',
                    fontWeight: 'bold',
                    mb: 4,
                    textShadow: '2px 2px 4px rgba(0,0,0,0.2)'
                }}>
                    {letter.toUpperCase()}
                </Typography>
            </motion.div>

            {/* Example Words */}
            <Grid container spacing={3} justifyContent="center">
                {examples.map((word, index) => (
                    <Grid item xs={6} sm={6} md={4} key={word}>
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: index * 0.1 + 0.3 }}
                        >
                            <Typography variant="h5" sx={{
                                bgcolor: 'action.hover',
                                p: 2,
                                borderRadius: 2,
                                fontSize: '1.5rem',
                                fontWeight: 500
                            }}>
                                {highlightLetter(word)}
                            </Typography>
                        </motion.div>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

// Usage example:
// <LetterExamples
//   letter="أ"
//   examples={["أبجد", "كتاب", "إسلام", "آخر", "باب"]}
//   language="arabic"
// />

export default LetterExamples;
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Outlet } from 'react-router-dom';
import { Box, Button, Typography, Grid, Container } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import rtlPlugin from 'stylis-plugin-rtl';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import arabicLetters from '../data/arabicLetters.json';
import frenchLetters from '../data/frenchLetters.json';
import englishLetters from '../data/englishLetters.json';
import { kidsFriendlyColors } from '../theme/colors';

const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

const cacheLtr = createCache({
    key: 'muiltr',
});

const languageSettings = {
    arabic: {
        title: 'اختر حرفاً للتعلم',
        highScore: 'أعلى نتيجة',
        direction: 'rtl',
        displayName: 'العربية',
        letters: arabicLetters.letters,
        distractors: arabicLetters.distractors,
    },
    french: {
        title: 'Choisissez une lettre',
        highScore: 'Meilleur score',
        direction: 'ltr',
        displayName: 'Français',
        letters: frenchLetters.letters,
        distractors: frenchLetters.distractors,
    },
    english: {
        title: 'Choose a letter',
        highScore: 'High Score',
        direction: 'ltr',
        displayName: 'English',
        letters: englishLetters.letters,
        distractors: englishLetters.distractors,
    },
};

const LettersGame = () => {
    const { language } = useParams();
    const navigate = useNavigate();
    const [highScore, setHighScore] = useState(0);
    const [selectedLanguage, setSelectedLanguage] = useState(language);

    const currentSettings = languageSettings[selectedLanguage] || languageSettings.arabic;

    useEffect(() => {
        const savedHighScore = localStorage.getItem('lettersGameHighScore');
        if (savedHighScore) setHighScore(parseInt(savedHighScore));
    }, []);

    useEffect(() => {
        if (language && language !== selectedLanguage) {
            setSelectedLanguage(language);
        }
    }, [language]);

    const updateHighScore = (newScore) => {
        if (newScore > highScore) {
            setHighScore(newScore);
            localStorage.setItem('lettersGameHighScore', newScore.toString());
        }
    };

    const handleLanguageChange = (newLanguage) => {
        navigate(`/letters-game/${newLanguage}`);
    };

    const theme = createTheme({
        direction: currentSettings.direction,
        typography: {
            fontFamily: 'Arial, sans-serif',
        },
    });

    if (!language || !languageSettings[language]) {
        return (
            <Container maxWidth="lg">
                <Typography variant="h4" color="error">
                    Invalid language selection
                </Typography>
            </Container>
        );
    }

    return (
        <CacheProvider value={currentSettings.direction === 'rtl' ? cacheRtl : cacheLtr}>
            <ThemeProvider theme={theme}>
                <Container maxWidth="lg">
                    <Box
                        sx={{
                            minHeight: '90vh',
                            p: { xs: 2, sm: 4 },
                            backgroundColor: kidsFriendlyColors.background,
                            borderRadius: '20px',
                            my: 3,
                            direction: currentSettings.direction,
                        }}
                    >
                        <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>
                            {currentSettings.title}
                        </Typography>
                        <Typography variant="h6" sx={{ mb: 4, textAlign: 'center' }}>
                            {currentSettings.highScore}: {highScore}
                        </Typography>

                        {/* Pass updateHighScore via Outlet context */}
                        <Outlet context={{ updateHighScore, languageSettings: currentSettings }} />

                        {/* Language Selector */}
                        <Box sx={{ display: 'flex', gap: 2, mb: 4, justifyContent: 'center' }}>
                            {Object.entries(languageSettings).map(([key, settings]) => (
                                <Button
                                    key={key}
                                    variant={language === key ? 'contained' : 'outlined'}
                                    onClick={() => handleLanguageChange(key)}
                                    sx={{
                                        backgroundColor: language === key ? kidsFriendlyColors.primary : 'transparent',
                                        color: language === key ? 'white' : kidsFriendlyColors.primary,
                                        '&:hover': {
                                            backgroundColor: language === key
                                                ? kidsFriendlyColors.primary
                                                : 'rgba(0, 0, 0, 0.04)',
                                        },
                                    }}
                                >
                                    {settings.displayName}
                                </Button>
                            ))}
                        </Box>

                        {/* Letters Grid */}
                        <Grid container spacing={2} justifyContent="center">
                            {currentSettings.letters.map((letterData, index) => (
                                <Grid item key={letterData.letter}>
                                    <Button
                                        variant="contained"
                                        onClick={() => navigate(`/letters-game/${language}/${letterData.letter}`)}
                                        sx={{
                                            minWidth: 70,
                                            height: 70,
                                            fontSize: 24,
                                            backgroundColor: [
                                                kidsFriendlyColors.primary,
                                                kidsFriendlyColors.secondary,
                                                kidsFriendlyColors.accent1,
                                                kidsFriendlyColors.accent2,
                                                kidsFriendlyColors.accent3,
                                            ][index % 5],
                                            '&:hover': { transform: 'scale(1.1)' },
                                        }}
                                    >
                                        {letterData.letter}
                                    </Button>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Container>
            </ThemeProvider>
        </CacheProvider>
    );
};

export default LettersGame;
import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useCart } from 'react-use-cart';
import {
    Container,
    Typography,
    Box,
    Grid,
    Stack,
    Card,
    CardContent,
    IconButton,
    CircularProgress,
    Button,
    TextField
} from '@mui/material';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { fetchSingleProduct } from "../store/slices/productsSlice";
import { useDispatch } from "react-redux";
import moment from 'moment';
import AddProductToCard from "../Component/AddProductToCard";
import QrCodeShow from "../Component/write-and-erase/QrCodeShow";
import TunisianRegionRank from "../Component/write-and-erase/TunisianRegionRank";

const API_URL = "https://www.api.sghiri.tn/images/products/";

const WriteEraseProducts = () => {
    const {
        addItem,
        items,
        inCart,
        removeItem,
        updateItemQuantity,
        isEmpty
    } = useCart();
    const dispatch = useDispatch();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hoveredCard, setHoveredCard] = useState(null);
    const [addingItem, setAddingItem] = useState(null);
    const [currentImageIndices, setCurrentImageIndices] = useState({});
    const addProductToCardRef = useRef(null); // Ref for AddProductToCard section

    const productIds = [252, 253, 254];

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productsData = await Promise.all(
                    productIds.map(id => dispatch(fetchSingleProduct(id)))
                );
                const loadedProducts = productsData
                    .filter(res => res.payload?.product)
                    .map(res => res.payload.product);
                setProducts(loadedProducts);
                setCurrentImageIndices(
                    loadedProducts.reduce((acc, product) => ({
                        ...acc,
                        [product.id]: 0
                    }), {})
                );
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch products:", error);
                setLoading(false);
            }
        };
        fetchProducts();
    }, [dispatch]);

    useEffect(() => {
        if (products.length === 0) return;

        const interval = setInterval(() => {
            setCurrentImageIndices(prevIndices => {
                const newIndices = { ...prevIndices };
                products.forEach(product => {
                    if (product.images && product.images.length > 1) {
                        newIndices[product.id] = (prevIndices[product.id] + 1) % product.images.length;
                    }
                });
                return newIndices;
            });
        }, 3000);

        return () => clearInterval(interval);
    }, [products]);

    const getDiscountedPrice = (product) => {
        if (!product.promos || product.promos.length === 0) return parseFloat(product.price);
        const originalPrice = parseFloat(product.price);
        const discount = product.promos[0].reduction;
        return originalPrice * (1 - discount / 100);
    };

    const handleCartAction = async (product) => {
        setAddingItem(product.id);
        try {
            if (inCart(product.id)) {
                await removeItem(product.id);
            } else {
                const cartItem = {
                    id: product.id,
                    name: product.name,
                    price: getDiscountedPrice(product),
                    originalPrice: parseFloat(product.price),
                    promo: product.promos?.[0] || null,
                    image: product.images?.[0]?.image ? `${API_URL}${product.images[0].image}` : '',
                    quantity: 1
                };
                await addItem(cartItem);
            }
        } finally {
            setAddingItem(null);
        }
    };

    const handleQuantityChange = (productId, newQuantity) => {
        if (newQuantity < 1) {
            removeItem(productId);
        } else {
            updateItemQuantity(productId, newQuantity);
        }
    };

    const getCartQuantity = (productId) => {
        const item = items.find(item => item.id === productId);
        return item ? item.quantity : 0;
    };

    const scrollToAddProductToCard = () => {
        if (addProductToCardRef.current) {
            addProductToCardRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    if (loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 4 }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ py: 6, direction: 'rtl' }}>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                {/* Header */}
                <Box textAlign="center" mb={6}>
                    <Typography variant="h3" sx={{ fontWeight: 700, color: 'primary.main', mb: 1 }}>
                        كرّاسات الكتابة المتعددة
                    </Typography>
                    <Typography variant="h6" sx={{ color: 'text.secondary' }}>
                        تعلم ممتع يمكن مسحه وإعادة استخدامه
                    </Typography>
                </Box>

                {/* Products Grid */}
                <Grid container spacing={4}>
                    {products.map((product) => {
                        const isHovered = hoveredCard === product.id;
                        const discountedPrice = getDiscountedPrice(product);
                        const hasPromo = product.promos && product.promos.length > 0;
                        const daysLeft = hasPromo ? moment(product.promos[0].end).diff(moment(), 'days') : 0;
                        const isInCart = inCart(product.id);
                        const isAdding = addingItem === product.id;
                        const cartQuantity = getCartQuantity(product.id);
                        const currentImageIndex = currentImageIndices[product.id] || 0;

                        return (
                            <Grid item xs={12} sm={6} md={4} key={product.id}>
                                <motion.div
                                    whileHover={{ y: -5 }}
                                    onHoverStart={() => setHoveredCard(product.id)}
                                    onHoverEnd={() => setHoveredCard(null)}
                                >
                                    <Card sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        borderRadius: 3,
                                        overflow: 'hidden',
                                        position: 'relative',
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        transition: 'transform 0.3s',
                                        transform: isHovered ? 'translateY(-5px)' : 'none'
                                    }}>
                                        {/* Image Animation with Framer Motion */}
                                        {product.images?.length > 0 ? (
                                            <Box sx={{ height: 240, position: 'relative', overflow: 'hidden', backgroundColor: '#f9f9f9' }}>
                                                <AnimatePresence mode="wait">
                                                    <motion.img
                                                        key={currentImageIndex}
                                                        src={`${API_URL}${product.images[currentImageIndex].image}`}
                                                        alt={`${product.name} ${currentImageIndex + 1}`}
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        exit={{ opacity: 0 }}
                                                        transition={{ duration: 0.5 }}
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'contain'
                                                        }}
                                                        onError={(e) => {
                                                            console.error(`Failed to load image: ${API_URL}${product.images[currentImageIndex].image}`);
                                                            e.target.style.display = 'none';
                                                        }}
                                                    />
                                                </AnimatePresence>
                                            </Box>
                                        ) : (
                                            <Box sx={{ height: 240, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#f9f9f9' }}>
                                                <Typography variant="body2" color="text.secondary">
                                                    لا توجد صور متاحة
                                                </Typography>
                                            </Box>
                                        )}

                                        {/* Promo Badge */}
                                        {hasPromo && (
                                            <Box sx={{
                                                position: 'absolute',
                                                top: 10,
                                                left: 10,
                                                backgroundColor: 'error.main',
                                                color: 'white',
                                                px: 1.5,
                                                py: 0.5,
                                                borderRadius: 2,
                                                fontSize: '0.875rem',
                                                fontWeight: 'bold',
                                                zIndex: 1
                                            }}>
                                                خصم {product.promos[0].reduction}%
                                            </Box>
                                        )}

                                        {/* Product Content */}
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 600 }}>
                                                {product.name}
                                            </Typography>

                                            {/* Product Details */}
                                            <Stack spacing={1} sx={{ mb: 2 }}>
                                                {product.contents_sizes?.[0]?.size_ar && (
                                                    <Typography variant="body2" color="text.secondary">
                                                        المقاس: {product.contents_sizes[0].size_ar}
                                                    </Typography>
                                                )}
                                                {product.contents?.[0]?.content_ar && (
                                                    <Typography variant="body2" color="text.secondary">
                                                        الصفحات: {product.contents[0].content_ar}
                                                    </Typography>
                                                )}
                                                {product.Ages && (
                                                    <Typography variant="body2" color="text.secondary">
                                                        العمر: {product.Ages}
                                                    </Typography>
                                                )}
                                            </Stack>

                                            {/* Price and Cart Actions with Quantity */}
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                mt: 'auto',
                                                flexDirection: 'row-reverse'
                                            }}>
                                                <Box sx={{ textAlign: 'right' }}>
                                                    {hasPromo ? (
                                                        <>
                                                            <Typography variant="h6" sx={{ color: 'error.main', fontWeight: 'bold' }}>
                                                                {discountedPrice.toFixed(3)} د.ت
                                                            </Typography>
                                                            <Typography variant="body2" sx={{ textDecoration: 'line-through' }}>
                                                                {parseFloat(product.price).toFixed(3)} د.ت
                                                            </Typography>
                                                            {daysLeft > 0 && (
                                                                <Typography variant="caption" sx={{ color: 'error.main' }}>
                                                                    متبقي {daysLeft} يوم
                                                                </Typography>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                                            {parseFloat(product.price).toFixed(3)} د.ت
                                                        </Typography>
                                                    )}
                                                </Box>

                                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                    {isInCart ? (
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: 1 }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => handleQuantityChange(product.id, cartQuantity - 1)}
                                                                    disabled={isAdding || cartQuantity <= 1}
                                                                    sx={{ color: 'error.main' }}
                                                                >
                                                                    <RemoveIcon />
                                                                </IconButton>
                                                                <Typography variant="body2" sx={{ fontWeight: 'bold', minWidth: 20, textAlign: 'center' }}>
                                                                    {cartQuantity}
                                                                </Typography>
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => handleQuantityChange(product.id, cartQuantity + 1)}
                                                                    disabled={isAdding}
                                                                    sx={{ color: 'primary.main' }}
                                                                >
                                                                    <AddIcon />
                                                                </IconButton>
                                                                <IconButton
                                                                    color="error"
                                                                    aria-label="remove from cart"
                                                                    onClick={() => handleCartAction(product)}
                                                                    disabled={isAdding}
                                                                    sx={{
                                                                        backgroundColor: 'error.main',
                                                                        color: 'white',
                                                                        '&:hover': { backgroundColor: 'error.dark' }
                                                                    }}
                                                                >
                                                                    {isAdding ? (
                                                                        <CircularProgress size={24} color="inherit" />
                                                                    ) : (
                                                                        <RemoveShoppingCartIcon />
                                                                    )}
                                                                </IconButton>
                                                            </Box>
                                                            <Button
                                                                variant="outlined"
                                                                color="primary"
                                                                size="small"
                                                                onClick={scrollToAddProductToCard}
                                                                sx={{
                                                                    fontSize: '0.75rem',
                                                                    padding: '2px 8px',
                                                                    minWidth: 'auto'
                                                                }}
                                                            >
                                                                Passer la commande
                                                            </Button>
                                                        </Box>
                                                    ) : (
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            onClick={() => handleCartAction(product)}
                                                            disabled={isAdding}
                                                            sx={{
                                                                minWidth: 60,
                                                                backgroundColor: 'primary.main',
                                                                color: 'white',
                                                                '&:hover': { backgroundColor: 'primary.dark' }
                                                            }}
                                                        >
                                                            {isAdding ? (
                                                                <CircularProgress size={20} color="inherit" />
                                                            ) : (
                                                                'شراء'
                                                            )}
                                                        </Button>
                                                    )}
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </motion.div>
                            </Grid>
                        );
                    })}
                </Grid>
                <QrCodeShow/>
                <TunisianRegionRank/>

                <Box dir="ltr" sx={{ mt: 8, textAlign: 'center' }} ref={addProductToCardRef}>
                    <AddProductToCard />
                </Box>

                {/* Features Section */}
                <Box sx={{ mt: 8, textAlign: 'center' }}>
                    <Typography variant="h4" sx={{ fontWeight: 600, mb: 4, color: 'primary.dark' }}>
                        مميزات كرّاساتنا
                    </Typography>

                    <Grid container spacing={3}>
                        {[
                            "صفحات مغلفة حرارياً بمادة متينة",
                            "قابلة للكتابة والمسح بسهولة",
                            "تناسب جميع الاقلام اللبدية",
                            "تصميم جذاب يحفز التعلم",
                            "مناسبة للبيت والمدرسة",
                            "إعادة استخدام متعددة"
                        ].map((feature, i) => (
                            <Grid item xs={12} sm={6} md={4} key={i}>
                                <motion.div whileHover={{ y: -5 }}>
                                    <Card sx={{
                                        p: 3,
                                        height: '100%',
                                        borderRadius: 2,
                                        backgroundColor: 'background.paper',
                                        boxShadow: 2
                                    }}>
                                        <Typography variant="h6" sx={{ fontWeight: 500 }}>
                                            {feature}
                                        </Typography>
                                    </Card>
                                </motion.div>
                            </Grid>
                        ))}
                    </Grid>
                </Box>


            </motion.div>
        </Container>
    );
};

export default WriteEraseProducts;
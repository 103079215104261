import React, { useState, useEffect, useRef } from 'react';
import { Typography, Button, Grid, Card, CardContent, Box, Stack } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import RamadanNotebookCarousel from "../Component/ramadanNotebook/RamadanNotebookCarousel";
import { ArrowBackIos, ArrowForwardIos, Star, CheckCircle, CalendarToday, Mosque, LocalShipping } from "@mui/icons-material";
import Countdown from "react-countdown";
import RamadanNotebookModels from "../Component/ramadanNotebook/RamadanNotebookModels";

const generateImagePaths = (prefix, start, end) => {
    const paths = [];
    for (let i = start; i <= end; i++) {
        const paddedNumber = i.toString().padStart(2, '0');
        paths.push(`/ramadanNotebook/contents/webp/${prefix}${paddedNumber}.webp`);
    }
    return paths;
};

const pages = [
    '/ramadanNotebook/contents/webp/FirstPage-01.webp',
    '/ramadanNotebook/contents/webp/FirstPage-02.webp',
    '/ramadanNotebook/contents/webp/FirstPage-03.webp',
    '/ramadanNotebook/contents/webp/FirstPage-04.webp',
    '/ramadanNotebook/contents/webp/FirstPage-05.webp',
    '/ramadanNotebook/contents/webp/FirstPage-06.webp',
    ...generateImagePaths('Content_1_-', 1, 30),
    ...generateImagePaths('Content_2_-', 1, 30),
    ...generateImagePaths('Content_3_-', 1, 30),
];

const RamadanNotebook = () => {
    const [page, setPage] = useState(0);
    const [isRTL, setIsRTL] = useState(true);
    const selectModelRef = useRef(null); // Reference to the "Select Model" section

    const nextPage = () => {
        setPage((prev) => (prev < pages.length - 1 ? prev + 1 : prev));
    };

    const prevPage = () => {
        setPage((prev) => (prev > 0 ? prev - 1 : prev));
    };

    // Function to scroll to the "Select Model" section
    const scrollToSelectModel = () => {
        selectModelRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        const handleContextMenu = (e) => e.preventDefault();
        const handleDragStart = (e) => e.preventDefault();
        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('dragstart', handleDragStart);
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
            document.removeEventListener('dragstart', handleDragStart);
        };
    }, []);

    // Mock data for the notebook models (replace with your actual data)
    const notebookModels = [
        { image: '/ramadanNotebook/m1_1.webp', name: ' مفكرّة رمضان - M1', id: 205 },
        { image: '/ramadanNotebook/m2_1.webp', name: 'مفكرّة رمضان - M2', id: 207 },
        { image: '/ramadanNotebook/m3_1.webp', name: 'مفكرّة رمضان - M3', id: 208 },
        { image: '/ramadanNotebook/m4_1.webp', name: 'مفكرّة رمضان - M4', id: 209 },
        { image: '/ramadanNotebook/m5_1.webp', name: 'مفكرّة رمضان - M5', id: 210 },
        { image: '/ramadanNotebook/m6_1.webp', name: 'مفكرّة رمضان - M6', id: 211 },
    ];

    return (
        <Grid container spacing={2} justifyContent="center" sx={{ p: { xs: 2, md: 4 }, bgcolor: '#f5f5f5', minHeight: '100vh', direction: isRTL ? 'rtl' : 'ltr' }}>
            {/* Header */}
            <Grid item xs={12}>
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true }}
                >
                    <Typography variant="h2" align="center" sx={{ mb: 4, fontWeight: 'bold', color: '#2e7d32' }}>
                        مفكرة رمضان 2025
                    </Typography>
                </motion.div>
            </Grid>

            {/* Product Grid */}
            <Grid container spacing={4} justifyContent="center">
                {/* Product Image */}
                <Grid item xs={12}>
                    <Grid container justifyContent="center" sx={{ width: '100%', height: { xs: '300px', sm: '400px', md: '500px' }, maxWidth: '800px', mx: 'auto' }}>
                        <RamadanNotebookCarousel notebookModels={notebookModels} />
                    </Grid>
                </Grid>

                {/* Product Details */}
                <Grid item xs={12}>
                    <motion.div
                        initial={{ opacity: 0, x: 50 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8 }}
                        viewport={{ once: true }}
                    >
                        <Card
                            sx={{
                                p: 4,
                                boxShadow: 6,
                                borderRadius: 2,
                                bgcolor: '#ffffff',
                                backgroundImage: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                variant="h3"
                                gutterBottom
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#2e7d32',
                                    mb: 3,
                                    fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' },
                                }}
                            >
                                مفكرة رمضان المميزة
                            </Typography>
                            <Typography
                                variant="h4"
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#2e7d32',
                                    mb: 2,
                                    fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
                                }}
                            >
                                السعر: <span style={{ color: '#1b5e20' }}>17 دينار</span>
                            </Typography>
                            <Typography
                                variant="h5"
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#2e7d32',
                                    mb: 3,
                                    fontSize: { xs: '1.25rem', sm: '1.5rem', md: '1.75rem' },
                                }}
                            >
                                متوفرة في 6 موديلات
                            </Typography>
                            <Stack direction="row"  justifyContent="center">
                                <Button
                                    variant="contained"
                                    size="large"
                                    sx={{
                                        bgcolor: '#2e7d32',
                                        color: '#fff',
                                        '&:hover': { bgcolor: '#1b5e20' },
                                        fontSize: '1.25rem',
                                        py: 1.5,
                                        px: 4,
                                        ml : 2
                                    }}
                                    onClick={scrollToSelectModel} // Scroll to "Select Model" section
                                >
                                    اطلب الآن
                                </Button>
                                <Button
                                    variant="outlined"
                                    size="large"
                                    sx={{
                                        borderColor: '#2e7d32',
                                        color: '#2e7d32',
                                        '&:hover': { borderColor: '#1b5e20', color: '#1b5e20' },
                                        fontSize: '1.25rem',
                                        py: 1.5,
                                        px: 4,
                                    }}
                                    onClick={() => {
                                        const notebookViewerSection = document.getElementById('notebook-viewer');
                                        if (notebookViewerSection) {
                                            notebookViewerSection.scrollIntoView({ behavior: 'smooth' });
                                        }
                                    }}
                                >
                                    محتوى المفكرّة
                                </Button>
                            </Stack>
                        </Card>
                    </motion.div>
                </Grid>
            </Grid>

            {/* Features Section */}
            <Grid item xs={12} sx={{ mt: 6, textAlign: 'center' }}>
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true }}
                >
                    <Typography variant="h2" sx={{ fontWeight: 'bold', color: '#2e7d32', mb: 4 }}>
                        مميزات مفكرة رمضان 2025
                    </Typography>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true }}
                >
                    <Box
                        component="img"
                        src={"ramadanNotebook/inner_1.webp"}
                        sx={{ width: '100%', objectFit: 'cover', borderRadius: '8px', mb: 2 }}
                    />
                </motion.div>
                <Grid container spacing={4} justifyContent="center">
                    {[
                        {
                            icon: <Star sx={{ fontSize: 60, color: '#2e7d32' }} />,
                            image: '/images/feature1.webp', // Add image path
                            title: 'أنشطة يومية تربوية وتفاعلية',
                            description: 'تعزيز القيم الإسلامية لدى أطفالك من خلال أنشطة ممتعة.',
                        },
                        {
                            icon: <CheckCircle sx={{ fontSize: 60, color: '#2e7d32' }} />,
                            image: '/images/feature2.webp', // Add image path
                            title: 'تحديات تحفيزية',
                            description: 'للأهل والأبناء لتحقيق أهداف رمضانية بشكل يومي.',
                        },
                        {
                            icon: <CalendarToday sx={{ fontSize: 60, color: '#2e7d32' }} />,
                            image: '/images/feature3.webp', // Add image path
                            title: 'نظام تتبع الإنجازات',
                            description: 'لضمان تحقيق أهدافك الروحية والشخصية.',
                        },
                        {
                            icon: <Mosque sx={{ fontSize: 60, color: '#2e7d32' }} />,
                            image: '/images/feature4.webp', // Add image path
                            title: 'تصميم أنيق وجودة عالية',
                            description: 'يبقى كذكرى جميلة لك ولعائلتك.',
                        },
                    ].map((feature, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <motion.div
                                initial={{ opacity: 0, y: 50 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.2 }}
                                viewport={{ once: true }}
                            >
                                <Card sx={{ p: 3, bgcolor: '#ffffff', borderRadius: 2, boxShadow: 3 }}>
                                    {feature.icon}
                                    <Typography variant="h4" sx={{ fontWeight: 'bold', color: '#2e7d32', mt: 2 }}>
                                        {feature.title}
                                    </Typography>
                                    <Typography variant="h6" sx={{ color: '#555', mt: 1 }}>
                                        {feature.description}
                                    </Typography>
                                </Card>
                            </motion.div>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            {/* FOMO Section */}
            <Grid item xs={12} sx={{ mt: 6, textAlign: 'center' }}>
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true }}
                >
                    <Typography variant="h2" sx={{ fontWeight: 'bold', color: '#2e7d32', mb: 4 }}>
                        لا تفوت الفرصة!
                    </Typography>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.2 }}
                    viewport={{ once: true }}
                >
                    <Typography variant="h5" sx={{ color: '#555', mb: 4 }}>
                        مفكرة رمضان 2025 هي رفيقة طفلك اليومي لتحقيق أقصى استفادة من الشهر الكريم. لا تدع الفرصة تفوته لتجربة
                        تديون يومياته و تخليد ذكرى.
                    </Typography>
                </motion.div>
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                    viewport={{ once: true }}
                >
                    <Button
                        variant="contained"
                        size="large"
                        startIcon={<LocalShipping sx={{ fontSize: 20 }} />}
                        sx={{
                            bgcolor: '#2e7d32',
                            color: '#fff',
                            '&:hover': { bgcolor: '#1b5e20' },
                            fontSize: '1.5rem',
                            py: 2,
                            px: 4,
                        }}
                        onClick={scrollToSelectModel} // Scroll to "Select Model" section
                    >
                        📦 احجز نسختك قبل نفاد الكمية!
                    </Button>
                </motion.div>
            </Grid>

            {/* Notebook Viewer */}
            <Grid item xs={12} sx={{ mt: 6, textAlign: 'center' }} id="notebook-viewer">
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true }}
                >
                    <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#2e7d32', mb: 4 }}>
                        تصفح مفكرة رمضان
                    </Typography>
                </motion.div>
                <Box sx={{
                    width: '100%',
                    maxWidth: '800px',
                    height: { xs: '400px', sm: '600px', md: '800px' },
                    mx: 'auto',
                    position: 'relative',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: '#f5f5f5',
                }}>
                    <AnimatePresence mode="wait">
                        <motion.img
                            key={page}
                            src={pages[page]}
                            alt={`Page ${page + 1}`}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.5 }}
                            style={{ width: '100%', height: '100%', objectFit: 'contain', position: 'absolute' }}
                        />
                    </AnimatePresence>

                    <Box
                        onClick={prevPage}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '10px',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: 1,
                            bgcolor: 'rgba(255, 255, 255, 0.7)',
                            borderRadius: '50%',
                        }}
                    >
                        <ArrowBackIos sx={{ fontSize: 30, color: 'black' }} />
                    </Box>

                    <Box
                        onClick={nextPage}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            right: '10px',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: 1,
                            bgcolor: 'rgba(255, 255, 255, 0.7)',
                            borderRadius: '50%',
                        }}
                    >
                        <ArrowForwardIos sx={{ fontSize: 30, color: 'black' }} />
                    </Box>
                    <Typography
                        sx={{
                            position: 'absolute',
                            bottom: '10px',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            bgcolor: 'rgba(0, 0, 0, 0.6)',
                            color: 'white',
                            px: 2,
                            py: 1,
                            borderRadius: '8px',
                            fontSize: '1rem',
                        }}
                    >
                        {page + 1} / {pages.length}
                    </Typography>
                </Box>
            </Grid>

            {/* Countdown Section */}
            <Grid container item justifyContent={"center"} xs={12}>
                <motion.div
                    initial={{ opacity: 0, y: -50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                    viewport={{ once: true }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            bgcolor: "#1b5e20", // Darker green background
                            color: "white",
                            p: 3,
                            mt: 4,
                            borderRadius: "12px",
                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                            maxWidth: "400px",
                            mx: "auto"
                        }}
                    >
                        <Typography variant="h4" fontWeight="bold" mb={2}>
                            العد التنازلي لرمضان
                        </Typography>

                        <Countdown
                            date={"2025-03-01"}
                            renderer={({ days, hours, minutes, seconds }) => (
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Box sx={{
                                        p: 1,
                                        bgcolor: "white",
                                        color: "#ff0000", // Red color for numbers
                                        borderRadius: "8px",
                                        minWidth: "70px",
                                        textAlign: "center"
                                    }}>
                                        <Typography variant="h3" fontWeight="bold">{days}</Typography>
                                        <Typography variant="h6">أيّام</Typography>
                                    </Box>

                                    <Box sx={{
                                        p: 1,
                                        mr: 1,
                                        bgcolor: "white",
                                        color: "#ff0000", // Red color for numbers
                                        borderRadius: "8px",
                                        minWidth: "70px",
                                        textAlign: "center"
                                    }}>
                                        <Typography variant="h3" fontWeight="bold">{hours}</Typography>
                                        <Typography variant="h6">ساعة</Typography>
                                    </Box>

                                    <Box sx={{
                                        p: 1,
                                        bgcolor: "white",
                                        color: "#ff0000", // Red color for numbers
                                        borderRadius: "8px",
                                        minWidth: "70px",
                                        textAlign: "center"
                                    }}>
                                        <Typography variant="h3" fontWeight="bold">{minutes}</Typography>
                                        <Typography variant="h6">دقيقة</Typography>
                                    </Box>

                                    <Box sx={{
                                        p: 1,
                                        bgcolor: "white",
                                        color: "#ff0000", // Red color for numbers
                                        borderRadius: "8px",
                                        minWidth: "70px",
                                        textAlign: "center"
                                    }}>
                                        <Typography variant="h3" fontWeight="bold">{seconds}</Typography>
                                        <Typography variant="h6">ثانية</Typography>
                                    </Box>
                                </Stack>
                            )}
                        />
                    </Box>
                </motion.div>
                <RamadanNotebookModels notebookModels={notebookModels} selectModelRef={selectModelRef} />
            </Grid>
        </Grid>
    );
};

export default RamadanNotebook;
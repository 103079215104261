import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Container, Paper, IconButton, Stack } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams, useOutletContext } from 'react-router-dom';
import { VolumeUp, CheckCircle, Cancel, RestartAlt, Replay } from '@mui/icons-material';
import arabicLetters from '../data/arabicLetters.json';
import frenchLetters from '../data/frenchLetters.json';
import englishLetters from '../data/englishLetters.json';

const languageData = {
    arabic: arabicLetters,
    french: frenchLetters,
    english: englishLetters,
};

const Letters = () => {
    const { language, letter } = useParams();
    const { updateHighScore = () => {} } = useOutletContext() || {};

    const localizedText = {
        arabic: {
            title: (l) => `تعلم حرف ${l}`,
            score: 'النقاط',
            streak: (s) => `تتابع ${s}x`,
            correct: (p) => `+${p} نقاط!`,
            wrong: 'خطأ! -2 نقاط',
            completed: '🎉 لقد انهيت جميع الكلمات!',
            restart: 'اللعب مجدّدا',
            start: 'بدء اللعبة',
        },
        french: {
            title: (l) => `Apprendre la lettre ${l}`,
            score: 'Points',
            streak: (s) => `Série ${s}x`,
            correct: (p) => `+${p} points!`,
            wrong: 'Faux! -2 points',
            completed: '🎉 Vous avez terminé tous les mots!',
            restart: 'Redémarrer',
            start: 'Commencer le jeu',
        },
        english: {
            title: (l) => `Learn Letter ${l}`,
            score: 'Score',
            streak: (s) => `${s}x Streak`,
            correct: (p) => `+${p} points!`,
            wrong: 'Wrong! -2 points',
            completed: '🎉 You completed all words!',
            restart: 'Play Again',
            start: 'Start Game',
        },
    };

    const [gameState, setGameState] = useState({
        score: localStorage.getItem(`lettersScore_${language}`) || 0,
        streak: 0,
        totalCorrect: localStorage.getItem(`lettersTotalCorrect_${language}`) || 0,
        currentWord: null,
        options: [],
        feedback: null,
        sound: true,
        usedWords: [],
        loading: true,
        allWordsUsed: false,
    });

    const [gameStarted, setGameStarted] = useState(false);

    const currentLanguage = languageData[language] || englishLetters;
    const letterData = currentLanguage.letters.find(l => l.letter === letter);
    const text = localizedText[language] || localizedText.english;

    useEffect(() => {
        const savedScore = localStorage.getItem(`lettersScore_${language}`);
        const savedTotalCorrect = localStorage.getItem(`lettersTotalCorrect_${language}`);
        if (savedScore || savedTotalCorrect) {
            setGameState(prev => ({
                ...prev,
                score: parseInt(savedScore) || 0,
                totalCorrect: parseInt(savedTotalCorrect) || 0,
            }));
        }
    }, [letter, language]);

    useEffect(() => {
        localStorage.setItem(`lettersScore_${language}`, gameState.score.toString());
        localStorage.setItem(`lettersTotalCorrect_${language}`, gameState.totalCorrect.toString());
        updateHighScore(gameState.score);
    }, [gameState.score, gameState.totalCorrect]);

    const initializeGame = () => {
        if (!letterData?.correctWords) return;

        const availableWords = letterData.correctWords.filter(w =>
            !gameState.usedWords.includes(w.word)
        );

        if (availableWords.length === 0) {
            setGameState(prev => ({
                ...prev,
                score: localStorage.getItem(`lettersScore_${language}`) || 0,
                allWordsUsed: true,
            }));
            return;
        }

        const correctWord = availableWords[Math.floor(Math.random() * availableWords.length)];
        const distractors = currentLanguage.distractors
            .sort(() => Math.random() - 0.5)
            .slice(0, 2);

        setGameState(prev => ({
            ...prev,
            currentWord: correctWord,
            options: [correctWord, ...distractors].sort(() => Math.random() - 0.5),
            usedWords: [...prev.usedWords, correctWord.word],
            feedback: null,
            loading: false,
            allWordsUsed: false,
        }));

        if (gameState.sound && correctWord.sound) {
            try {
                const audio = new Audio(correctWord.sound);
                audio.play().catch(error => console.error("Error playing audio:", error));
            } catch (error) {
                console.error("Error loading audio:", error);
            }
        }
    };

    const [isAudioPlaying, setIsAudioPlaying] = useState(false);

    const replayAudio = () => {
        if (gameState.currentWord?.sound && !isAudioPlaying) {
            try {
                setIsAudioPlaying(true);
                const audio = new Audio(gameState.currentWord.sound);
                audio.play()
                    .then(() => {
                        audio.onended = () => setIsAudioPlaying(false);
                    })
                    .catch(error => {
                        console.error("Error playing audio:", error);
                        setIsAudioPlaying(false);
                    });
            } catch (error) {
                console.error("Error loading audio:", error);
                setIsAudioPlaying(false);
            }
        }
    };

    const handleAnswer = async (selectedWord) => {
        if (gameState.feedback || gameState.loading) return;

        const isCorrect = selectedWord === gameState.currentWord?.word;
        let newScore = gameState.score;
        let newStreak = isCorrect ? gameState.streak + 1 : 0;
        let newTotalCorrect = gameState.totalCorrect;

        if (isCorrect) {
            newTotalCorrect += 1;
            const points = newTotalCorrect % 5 === 0 ? 4 : 2;
            newScore = Math.max(0, gameState.score + points);
        } else {
            newScore = Math.max(0, gameState.score - 2);
        }

        setGameState(prev => ({
            ...prev,
            score: newScore,
            streak: newStreak,
            totalCorrect: newTotalCorrect,
            feedback: isCorrect ? 'correct' : 'wrong',
            loading: true,
        }));

        await new Promise(resolve => setTimeout(resolve, 1500));
        initializeGame();
    };

    const resetGame = () => {
        setGameState({
            score: localStorage.getItem(`lettersScore_${language}`) || 0,
            streak: 0,
            totalCorrect: localStorage.getItem(`lettersTotalCorrect_${language}`) || 0,
            currentWord: null,
            options: [],
            feedback: null,
            sound: true,
            usedWords: [],
            loading: false,
            allWordsUsed: false,
        });
        setGameStarted(false);
    };

    const startNewRound = () => {
        setGameState(prev => ({
            ...prev,
            usedWords: [],           // Reset used words to make all words available again
            allWordsUsed: false,     // Indicate the game is not completed
            streak: 0,               // Reset streak for a new round
        }));
        initializeGame();            // Load a new word and update game state
    };

    const startGame = () => {
        setGameStarted(true);
        initializeGame();
    };

    if (!letterData) {
        return (
            <Container maxWidth="sm" sx={{ textAlign: 'center', mt: 10 }}>
                <Typography variant="h4" color="error" fontFamily="'Bubblegum Sans', cursive">
                    {language === 'arabic' ? 'الحرف غير موجود' :
                        language === 'french' ? 'Lettre non trouvée' : 'Letter not found'}
                </Typography>
            </Container>
        );
    }

    return (
        <Container
            maxWidth={false}
            sx={{
                direction: language === 'arabic' ? 'rtl' : 'ltr',
                minHeight: '60vh',
                background: 'linear-gradient(135deg, #FFEB3B 0%, #FF9800 100%)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
                position: 'relative',
                overflow: 'hidden',
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'url("https://www.transparenttextures.com/patterns/circles.png")',
                    opacity: 0.1,
                },
            }}
        >
            <Box sx={{ width: '100%', maxWidth: 900, position: 'relative' }}>
                {/* Header Section */}
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    spacing={2}
                    alignItems={{ xs: 'stretch', sm: 'center' }}
                    sx={{ mb: 3, px: 2 }}
                >
                    <IconButton
                        onClick={() => setGameState(prev => ({ ...prev, sound: !prev.sound }))}
                        sx={{
                            backgroundColor: gameState.sound ? '#4CAF50' : '#F44336',
                            '&:hover': { backgroundColor: gameState.sound ? '#45A049' : '#D32F2F' },
                            boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
                            alignSelf: 'center',
                        }}
                    >
                        <VolumeUp sx={{ fontSize: 32, color: '#fff' }} />
                    </IconButton>
                    <IconButton
                        onClick={replayAudio}
                        disabled={isAudioPlaying || !gameState.currentWord}
                        sx={{
                            backgroundColor: (isAudioPlaying || !gameState.currentWord) ? '#B0BEC5' : (gameState.sound ? '#4CAF50' : '#F44336'),
                            '&:hover': {
                                backgroundColor: (isAudioPlaying || !gameState.currentWord) ? '#B0BEC5' : (gameState.sound ? '#45A049' : '#D32F2F')
                            },
                            boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
                            alignSelf: 'center',
                        }}
                    >
                        <Replay sx={{ fontSize: 32, color: '#fff' }} />
                    </IconButton>
                </Stack>

                {/* Main Content */}
                <Paper
                    elevation={6}
                    sx={{
                        p: { xs: 3, sm: 5 },
                        borderRadius: '30px',
                        background: '#fff',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        position: 'relative',
                        border: '4px solid #FF9800',
                        boxShadow: '0 10px 20px rgba(0,0,0,0.2)',
                    }}
                >
                    <AnimatePresence>
                        <Paper
                            elevation={3}
                            sx={{
                                px: 3,
                                py: 1.5,
                                borderRadius: '20px',
                                background: '#2196F3',
                                color: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
                            }}
                        >
                            <Typography variant="h6" fontFamily="'Bubblegum Sans', cursive">
                                {text.score}: <Box component="span" sx={{ fontWeight: 'bold' }}>{gameState.score}</Box>
                            </Typography>
                            {gameState.streak > 0 && (
                                <Typography variant="body1" sx={{ ml: 2, color: '#FFEB3B' }}>
                                    {text.streak(gameState.streak)}
                                </Typography>
                            )}
                        </Paper>

                        {!gameStarted ? (
                            <motion.div
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0 }}
                                style={{ textAlign: 'center' }}
                            >
                                <Typography
                                    variant="h2"
                                    sx={{
                                        mb: 4,
                                        fontFamily: "'Bubblegum Sans', cursive",
                                        color: '#E91E63',
                                        fontSize: { xs: '2rem', sm: '3rem' },
                                    }}
                                >
                                    {text.title(letter.toUpperCase())}
                                </Typography>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={startGame}
                                    sx={{
                                        backgroundColor: '#4CAF50',
                                        color: '#fff',
                                        borderRadius: '25px',
                                        px: 5,
                                        py: 2,
                                        fontSize: '1.5rem',
                                        fontFamily: "'Bubblegum Sans', cursive",
                                        boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
                                        '&:hover': { backgroundColor: '#45A049' },
                                    }}
                                >
                                    {text.start}
                                </Button>
                            </motion.div>
                        ) : gameState.allWordsUsed ? (
                            <motion.div
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0 }}
                                style={{ textAlign: 'center' }}
                            >
                                <Typography
                                    variant="h3"
                                    sx={{
                                        mb: 4,
                                        fontFamily: "'Bubblegum Sans', cursive",
                                        color: '#2196F3',
                                        fontSize: { xs: '1.8rem', sm: '2.5rem' },
                                    }}
                                >
                                    {text.completed}
                                </Typography>
                                <Button
                                    variant="contained"
                                    size="large"
                                    startIcon={<RestartAlt />}
                                    onClick={startNewRound}
                                    sx={{
                                        backgroundColor: '#FF5722',
                                        color: '#fff',
                                        borderRadius: '25px',
                                        px: 5,
                                        py: 2,
                                        fontSize: '1.5rem',
                                        fontFamily: "'Bubblegum Sans', cursive",
                                        boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
                                        '&:hover': { backgroundColor: '#E64A19' },
                                    }}
                                >
                                    {text.restart}
                                </Button>
                            </motion.div>
                        ) : (
                            <>
                                <Typography
                                    variant="h2"
                                    sx={{
                                        textAlign: 'center',
                                        mb: 3,
                                        fontFamily: "'Bubblegum Sans', cursive",
                                        color: '#FF9800',
                                        fontSize: { xs: '2rem', sm: '3rem' },
                                    }}
                                >
                                    {text.title(letter.toUpperCase())}
                                </Typography>

                                {gameState.currentWord && (
                                    <motion.div
                                        initial={{ scale: 0.9 }}
                                        animate={{ scale: 1 }}
                                        transition={{ type: 'spring', stiffness: 100 }}
                                        style={{ textAlign: 'center' }}
                                    >
                                        <img
                                            src={gameState.currentWord.image}
                                            alt="Word visual"
                                            style={{
                                                width: '100%',
                                                maxWidth: 200,
                                                height: 'auto',
                                                borderRadius: '20px',
                                                border: '4px solid #2196F3',
                                                boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                                                margin: '0 auto',
                                                display: 'block',
                                            }}
                                        />
                                    </motion.div>
                                )}

                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: { xs: 'repeat(3, 1fr)', sm: 'repeat(3, 1fr)' },
                                        gap: 2,
                                        mt: 4,
                                    }}
                                >
                                    <AnimatePresence>
                                        {gameState.options.map((option) => (
                                            <motion.div
                                                key={option.word}
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                exit={{ opacity: 0 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => handleAnswer(option.word)}
                                                    disabled={gameState.loading || gameState.feedback}
                                                    sx={{
                                                        backgroundColor: '#E91E63',
                                                        color: '#fff',
                                                        borderRadius: '20px',
                                                        py: 1.5,
                                                        fontSize: '1rem',
                                                        fontFamily: "'Bubblegum Sans', cursive",
                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                                        transition: 'transform 0.2s',
                                                        '&:hover': {
                                                            backgroundColor: '#D81B60',
                                                            transform: gameState.loading ? 'none' : 'scale(1.05)',
                                                        },
                                                        '&:disabled': {
                                                            backgroundColor: '#B0BEC5',
                                                            color: '#fff',
                                                        },
                                                    }}
                                                >
                                                    {option.word}
                                                </Button>
                                            </motion.div>
                                        ))}
                                    </AnimatePresence>
                                </Box>
                            </>
                        )}
                    </AnimatePresence>

                    {/* Feedback Overlay */}
                    <AnimatePresence>
                        {gameState.feedback && !gameState.allWordsUsed && (
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0 }}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    textAlign: 'center',
                                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                                    borderRadius: '20px',
                                    p: 3,
                                }}
                            >
                                {gameState.feedback === 'correct' ? (
                                    <CheckCircle sx={{ fontSize: 100, color: '#4CAF50' }} />
                                ) : (
                                    <Cancel sx={{ fontSize: 100, color: '#F44336' }} />
                                )}
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mt: 2,
                                        fontFamily: "'Bubblegum Sans', cursive",
                                        color: gameState.feedback === 'correct' ? '#4CAF50' : '#F44336',
                                    }}
                                >
                                    {gameState.feedback === 'correct'
                                        ? text.correct(gameState.totalCorrect % 5 === 0 ? 4 : 2)
                                        : text.wrong}
                                </Typography>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </Paper>
            </Box>
        </Container>
    );
};

export default Letters;
import React from 'react';
import {
  Container,
  Grid,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Box
} from '@mui/material';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import LetterExamples from '../Component/letters_game/LetterExamples';
import arabicLetters from '../data/arabicLetters.json';
import frenchLetters from '../data/frenchLetters.json';
import englishLetters from '../data/englishLetters.json';
import Letters from './Letters';
import MissingLetterGame from "../Component/letters_game/MissingLetterGame";
import LetterMatchingGame from "../Component/letters_game/LetterMatchingGame";
import LetterCountGame from "../Component/letters_game/LetterCountGame";

const sectionVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.4 }
  }
};

const getLanguageData = (language) => {
  switch(language) {
    case 'arabic': return arabicLetters.letters;
    case 'french': return frenchLetters.letters;
    case 'english': return englishLetters.letters;
    default: return [];
  }
};

const translations = {
  english: {
    interactiveLetters: "Interactive Letters",
    letterTypes: "Letter Types",
    letterTypesItems: ['Uppercase (A-Z)', 'Lowercase (a-z)', 'Vowels', 'Consonants'],
    usageExamples: "Usage Examples",
    example: "Example",
    learningTips: "Learning Tips",
    tips: [
      'Practice daily writing',
      'Focus on letter sounds',
      'Use contextual examples',
      'Start with common letters',
      'Mix recognition and writing'
    ],
    missingLetterGame: "Missing Letter Game",
    letterMatchingGame: "Letter Matching Game",
    letterCountGame: "Letter Count Game"
  },
  french: {
    interactiveLetters: "Lettres Interactives",
    letterTypes: "Types de Lettres",
    letterTypesItems: ['Majuscules (A-Z)', 'Minuscules (a-z)', 'Voyelles', 'Consonnes'],
    usageExamples: "Exemples d'Utilisation",
    example: "Exemple",
    learningTips: "Conseils d'Apprentissage",
    tips: [
      'Pratiquez l’écriture quotidiennement',
      'Concentrez-vous sur les sons des lettres',
      'Utilisez des exemples contextuels',
      'Commencez par les lettres courantes',
      'Mélangez reconnaissance et écriture'
    ],
    missingLetterGame: "Jeu de Lettre Manquante",
    letterMatchingGame: "Jeu d'Appariement de Lettres",
    letterCountGame: "Jeu de Comptage de Lettres"
  },
  arabic: {
    interactiveLetters: "الحروف التفاعلية",
    letterTypes: "أنواع الحروف",
    letterTypesItems: ['الحروف الكبيرة', 'الحروف الصغيرة', 'الحروف الصوتية', 'الحروف الساكنة'],
    usageExamples: "أمثلة الاستخدام",
    example: "مثال",
    learningTips: "نصائح التعلم",
    tips: [
      'مارس الكتابة يوميًا',
      'ركز على أصوات الحروف',
      'استخدم أمثلة سياقية',
      'ابدأ بالحروف الشائعة',
      'امزج بين التعرف والكتابة'
    ],
    missingLetterGame: "لعبة الحرف الناقص",
    letterMatchingGame: "لعبة مطابقة الحروف",
    letterCountGame: "لعبة عد الحروف"
  }
};

const LetterBasicComponent = () => {
  const { language, letter } = useParams();



  // Get data based on language
  const languageData = getLanguageData(language);
  const texts = translations[language] || translations.english; // Fallback to English

  // Find current letter data
  const letterData = languageData.find(l => l.letter === letter?.toUpperCase());

  // Get 10 random example words containing the specific letter
  const examples = letterData?.wordsExamples
      .filter(wordObj => wordObj.toLowerCase().includes(letter.toLowerCase())) // Filter words containing the letter
      .sort(() => Math.random() - 0.5) // Shuffle the array
      .slice(0, 10) // Select the first 10 words
      .map(wordObj => wordObj) || [];

  return (
      <Container maxWidth="md" sx={{ py: 4 }}>
        {/* Main Heading */}
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
        >
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 4 }}>
            {letter?.toUpperCase()} - {texts.interactiveLetters}
          </Typography>
          <LetterExamples letter={letter} examples={examples} language={language} />
        </motion.div>

        {/* Interactive Letters Section */}
        <motion.section
            variants={sectionVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
        >
          <Paper elevation={3} sx={{ mb: 4, p: 3, borderRadius: 2 }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
              {texts.interactiveLetters}
            </Typography>
            <Letters letterData={letterData} />
          </Paper>
        </motion.section>

        {/* Missing Letter Game Section */}
        <motion.section
            variants={sectionVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
        >
          <Paper elevation={3} sx={{ mb: 4, p: 3, borderRadius: 2 }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
              {texts.missingLetterGame}
            </Typography>
            <MissingLetterGame letterData={letterData} />
          </Paper>
        </motion.section>

        {/* Letter Matching Game Section */}
        <motion.section
            variants={sectionVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
        >
          <Paper elevation={3} sx={{ mb: 4, p: 3, borderRadius: 2 }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
              {texts.letterMatchingGame}
            </Typography>
            <LetterMatchingGame letterData={letterData} />
          </Paper>
        </motion.section>

        {/* Letter Count Game Section */}
        <motion.section
            variants={sectionVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
        >
          <Paper elevation={3} sx={{ mb: 4, p: 3, borderRadius: 2 }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', mb: 3 }}>
              {texts.letterCountGame}
            </Typography>
            <LetterCountGame />
          </Paper>
        </motion.section>
      </Container>
  );
};

export default LetterBasicComponent;
import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid } from '@mui/material';

const QrCodeShow = () => {
    const images = [
        'write-erase/qr-code.png', // Adjust paths based on your project structure
        'write-erase/phone.png',
        'write-erase/phone2.png',
        'write-erase/phone3.png',
        'write-erase/phone4.png',
    ];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 2000); // Change image every 2 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [images.length]);

    return (
        <Box
            sx={{
                mt: 8,
                textAlign: 'center',
                px: { xs: 2, sm: 0 }, // Responsive padding
            }}
        >
            <Typography
                variant="h4"
                sx={{
                    fontWeight: 600,
                    mb: 4,
                    color: 'primary.dark',
                    fontSize: { xs: '1.5rem', sm: '2rem' }, // Responsive font size
                }}
            >
                ألعاب إضافية لكّل حرف مع الصوت و الصور
            </Typography>
            <Typography
                variant="h6"
                sx={{
                    fontWeight: 600,
                    mb: 4,
                    color: 'primary.dark',
                    fontSize: { xs: '1.2rem', sm: '1.5rem' }, // Responsive font size
                }}
            >
                يصل إليها الطفل عبر مسح الــ Qr code في صفحة الحرف
            </Typography>
            <Grid container justifyContent="center">
                <Grid item xs={12}>
                    <img
                        src={images[currentImageIndex]}
                        alt="Animation sequence"
                        style={{
                            maxWidth: '500px',
                            width: '100%', // Responsive width
                            height: 'auto',
                            opacity: 1,
                            transition: 'opacity 0.5s ease-in-out', // Fade transition
                        }}
                        onLoad={(e) => {
                            // Ensure smooth transition by resetting opacity after load
                            e.target.style.opacity = 1;
                        }}
                        onError={(e) => {
                            e.target.src = 'write-erase/phone.png'; // Fallback image
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
};

export default QrCodeShow;
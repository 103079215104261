import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Container, Paper, IconButton, Stack } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useParams, useOutletContext } from 'react-router-dom';
import { VolumeUp, CheckCircle, Cancel, RestartAlt, Replay } from '@mui/icons-material';
import arabicLetters from '../../data/arabicLetters.json';
import frenchLetters from '../../data/frenchLetters.json';
import englishLetters from '../../data/englishLetters.json';

// Language data mapping
const languageData = {
    arabic: arabicLetters,
    french: frenchLetters,
    english: englishLetters,
};

// Localized text for different languages
const localizedText = {
    arabic: {
        title: (l) => `تعلم حرف ${l}`,
        instructions: [
            '1. استمع إلى الكلمة',
            '2. اختر الحرف الصحيح لإكمال الكلمة.',
            '3. إذا كانت إجابتك صحيحة، ستحصل على نقاط!',
            '4. إذا كانت إجابتك خاطئة، حاول مرة أخرى!',
        ],
        score: 'النقاط',
        streak: (s) => `تتابع ${s}x`,
        correct: (p) => `+${p} نقاط!`,
        wrong: 'خطأ! -2 نقاط',
        completed: '🎉 لقد انهيت جميع الكلمات!',
        restart: 'اللعب مجدّدا',
        start: 'بدء اللعبة',
    },
    french: {
        title: (l) => `Apprendre la lettre ${l}`,
        instructions: [
            '1. Écoutez le mot.',
            '2. Choisissez la bonne lettre pour compléter le mot.',
            '3. Si votre réponse est correcte, vous gagnerez des points!',
            '4. Si votre réponse est incorrecte, réessayez!',
        ],
        score: 'Points',
        streak: (s) => `Série ${s}x`,
        correct: (p) => `+${p} points!`,
        wrong: 'Faux! -2 points',
        completed: '🎉 Vous avez terminé tous les mots!',
        restart: 'Redémarrer',
        start: 'Commencer le jeu',
    },
    english: {
        title: (l) => `Learn Letter ${l}`,
        instructions: [
            '1. Listen to the word',
            '2. Choose the correct letter to complete the word.',
            '3. If your answer is correct, you will earn points!',
            '4. If your answer is wrong, try again!',
        ],
        score: 'Score',
        streak: (s) => `${s}x Streak`,
        correct: (p) => `+${p} points!`,
        wrong: 'Wrong! -2 points',
        completed: '🎉 You completed all words!',
        restart: 'Play Again',
        start: 'Start Game',
    },
};

const MissingLetterGame = () => {
    const { language, letter } = useParams();
    const { updateHighScore = () => {} } = useOutletContext() || {};

    const [gameState, setGameState] = useState({
        score: localStorage.getItem(`missingLetterScore_${language}`) || 0,
        streak: 0,
        totalCorrect: localStorage.getItem(`missingLetterTotalCorrect_${language}`) || 0,
        currentWord: null,
        missingLetterIndex: null,
        options: [],
        feedback: null,
        sound: true,
        usedWords: [],
        loading: true,
        allWordsUsed: false,
    });

    const [gameStarted, setGameStarted] = useState(false);

    const currentLanguage = languageData[language] || englishLetters;
    const text = localizedText[language] || localizedText.english;

    useEffect(() => {
        const savedScore = localStorage.getItem(`missingLetterScore_${language}`);
        const savedTotalCorrect = localStorage.getItem(`missingLetterTotalCorrect_${language}`);
        if (savedScore || savedTotalCorrect) {
            setGameState((prev) => ({
                ...prev,
                score: parseInt(savedScore) || 0,
                totalCorrect: parseInt(savedTotalCorrect) || 0,
            }));
        }
    }, [language]);

    useEffect(() => {
        localStorage.setItem(`missingLetterScore_${language}`, gameState.score.toString());
        localStorage.setItem(`missingLetterTotalCorrect_${language}`, gameState.totalCorrect.toString());
        updateHighScore(gameState.score);
    }, [gameState.score, gameState.totalCorrect]);

    const getWordsWithLetter = () => {
        return currentLanguage.letters.flatMap((l) => l.correctWords).filter((word) =>
            word.word.toLowerCase().includes(letter.toLowerCase())
        );
    };

    const initializeGame = () => {
        const allWordsWithLetter = getWordsWithLetter();
        const availableWords = allWordsWithLetter.filter(
            (w) => !gameState.usedWords.includes(w.word)
        );

        if (availableWords.length === 0) {
            setGameState((prev) => ({ ...prev, allWordsUsed: true }));
            return;
        }

        const correctWord = availableWords[Math.floor(Math.random() * availableWords.length)];
        const wordText = correctWord.word.toLowerCase();
        const letterIndices = wordText.split('').reduce((acc, char, index) => {
            if (char === letter.toLowerCase()) acc.push(index);
            return acc;
        }, []);
        const missingIndex = letterIndices[Math.floor(Math.random() * letterIndices.length)];

        const alphabet = currentLanguage.letters.map((l) => l.letter);
        const distractors = alphabet
            .filter((l) => l.toLowerCase() !== letter.toLowerCase())
            .sort(() => Math.random() - 0.5)
            .slice(0, 2);
        const options = [letter, ...distractors].sort(() => Math.random() - 0.5);

        setGameState((prev) => ({
            ...prev,
            currentWord: correctWord,
            missingLetterIndex: missingIndex,
            options: options,
            usedWords: [...prev.usedWords, correctWord.word],
            feedback: null,
            loading: false,
            allWordsUsed: false,
        }));

        if (gameState.sound && correctWord.sound) {
            try {
                const audio = new Audio(correctWord.sound);
                audio.play().catch((error) => console.error('Error playing audio:', error));
            } catch (error) {
                console.error('Error loading audio:', error);
            }
        }
    };

    const [isAudioPlaying, setIsAudioPlaying] = useState(false);
    const replayAudio = () => {
        if (gameState.currentWord?.sound && !isAudioPlaying) {
            setIsAudioPlaying(true);
            const audio = new Audio(gameState.currentWord.sound);
            audio
                .play()
                .then(() => {
                    audio.onended = () => setIsAudioPlaying(false);
                })
                .catch((error) => {
                    console.error('Error playing audio:', error);
                    setIsAudioPlaying(false);
                });
        }
    };

    const handleAnswer = async (selectedLetter) => {
        if (gameState.feedback || gameState.loading) return;

        const isCorrect = selectedLetter.toLowerCase() === letter.toLowerCase();
        let newScore = gameState.score;
        let newStreak = isCorrect ? gameState.streak + 1 : 0;
        let newTotalCorrect = gameState.totalCorrect;

        if (isCorrect) {
            newTotalCorrect += 1;
            const points = newTotalCorrect % 5 === 0 ? 4 : 2;
            newScore = Math.max(0, gameState.score + points);
        } else {
            newScore = Math.max(0, gameState.score - 2);
        }

        setGameState((prev) => ({
            ...prev,
            score: newScore,
            streak: newStreak,
            totalCorrect: newTotalCorrect,
            feedback: isCorrect ? 'correct' : 'wrong',
            loading: true,
        }));

        await new Promise((resolve) => setTimeout(resolve, 1500));
        initializeGame();
    };

    const resetGame = () => {
        setGameState({
            score: localStorage.getItem(`missingLetterScore_${language}`) || 0,
            streak: 0,
            totalCorrect: localStorage.getItem(`missingLetterTotalCorrect_${language}`) || 0,
            currentWord: null,
            missingLetterIndex: null,
            options: [],
            feedback: null,
            sound: true,
            usedWords: [],
            loading: false,
            allWordsUsed: false,
        });
        setGameStarted(false);
    };

    const startNewRound = () => {
        setGameState((prev) => ({
            ...prev,
            usedWords: [],
            allWordsUsed: false,
            streak: 0,
        }));
        initializeGame();
    };

    const startGame = () => {
        setGameStarted(true);
        initializeGame();
    };

    const getDisplayWord = (word, missingIndex) => {
        if (!word || missingIndex === null) return '';
        const wordArray = word.split('');
        wordArray[missingIndex] = '_';
        return wordArray.join('');
    };
    const displayWord = gameState.currentWord
        ? getDisplayWord(gameState.currentWord.word, gameState.missingLetterIndex)
        : '';

    return (
        <Container
            maxWidth={false}
            sx={{
                direction: language === 'arabic' ? 'rtl' : 'ltr',
                background: 'linear-gradient(135deg, #A7C7E7 0%, #C6F0C2 100%)', // Soft blue to light green
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: { xs: 2, sm: 4 },
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    background: '#F9F9F9', // Light pastel background
                    borderRadius: '30px',
                    border: '4px solid #FFD700', // Golden border
                    boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
                    p: { xs: 2, sm: 4 },
                }}
            >
                {/* Header with sound controls */}
                <Stack
                    direction="row"
                    spacing={2} // Adjusted spacing for better visibility
                    justifyContent="center"
                    sx={{ mb: 3, width: '100%' }}
                >
                    <IconButton
                        onClick={() => setGameState((prev) => ({ ...prev, sound: !prev.sound }))}
                        sx={{
                            backgroundColor: gameState.sound ? '#4CAF50' : '#FF5252',
                            color: '#fff',
                            '&:hover': { backgroundColor: gameState.sound ? '#388E3C' : '#D32F2F' },
                        }}
                    >
                        <VolumeUp sx={{ fontSize: 32 }} />
                    </IconButton>
                    <IconButton
                        onClick={replayAudio}
                        disabled={isAudioPlaying || !gameState.currentWord}
                        sx={{
                            backgroundColor: isAudioPlaying || !gameState.currentWord ? '#BDBDBD' : '#4CAF50',
                            color: '#fff',
                            '&:hover': {
                                backgroundColor: isAudioPlaying || !gameState.currentWord ? '#BDBDBD' : '#388E3C',
                            },
                        }}
                    >
                        <Replay sx={{ fontSize: 32 }} />
                    </IconButton>
                </Stack>

                {/* Main game content */}
                <Paper
                    elevation={0}
                    sx={{
                        background: 'transparent',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: 3,
                    }}
                >
                    <AnimatePresence>
                        {/* Instructions */}
                        {gameStarted && !gameState.allWordsUsed && (
                            <Box
                                sx={{
                                    textAlign: 'center',
                                    mb: 2,
                                    p: 2,
                                    borderRadius: '20px',
                                    background: '#FFF3E0', // Soft peach
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                }}
                            >
                                {text.instructions.map((instruction, index) => (
                                    <Typography
                                        key={index}
                                        variant="body1"
                                        sx={{
                                            color: '#FF6F61', // Coral
                                            fontSize: { xs: '1rem', sm: '1.25rem' },
                                            mb: 1,
                                        }}
                                    >
                                        {instruction}
                                    </Typography>
                                ))}
                            </Box>
                        )}

                        {/* Score display */}
                        <Paper
                            elevation={3}
                            sx={{
                                px: 3,
                                py: 1.5,
                                borderRadius: '20px',
                                background: '#7b00ff', // Purple
                                color: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 2, // Added gap for spacing between children
                                boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
                            }}
                        >
                            <Typography variant="h6" fontFamily="'Comic Sans MS', cursive">
                                {text.score}: <Box component="span" sx={{ fontWeight: 'bold' }}>{gameState.score}</Box>
                            </Typography>
                            {gameState.streak > 0 && (
                                <Typography variant="body1" sx={{ color: '#f14444' }}>
                                    {text.streak(gameState.streak)}
                                </Typography>
                            )}
                        </Paper>

                        {/* Start screen */}
                        {!gameStarted ? (
                            <motion.div
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0 }}
                                style={{ textAlign: 'center' }}
                            >
                                <Typography
                                    variant="h2"
                                    sx={{
                                        mb: 4,
                                        fontFamily: "'Comic Sans MS', cursive",
                                        color: '#FF6F61',
                                        fontSize: { xs: '2rem', sm: '3rem' },
                                    }}
                                >
                                    {text.title(letter.toUpperCase())}
                                </Typography>
                                <Button
                                    variant="contained"
                                    size="large"
                                    onClick={startGame}
                                    sx={{
                                        backgroundColor: '#4CAF50',
                                        color: '#fff',
                                        borderRadius: '25px',
                                        px: 5,
                                        py: 2,
                                        fontSize: '1.5rem',
                                        fontFamily: "'Comic Sans MS', cursive",
                                        boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
                                        '&:hover': { backgroundColor: '#388E3C' },
                                    }}
                                >
                                    {text.start}
                                </Button>
                            </motion.div>
                        ) : gameState.allWordsUsed ? (
                            /* Completion screen */
                            <motion.div
                                initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0 }}
                                style={{ textAlign: 'center' }}
                            >
                                <Typography
                                    variant="h3"
                                    sx={{
                                        mb: 4,
                                        fontFamily: "'Comic Sans MS', cursive",
                                        color: '#37bede',
                                        fontSize: { xs: '1.8rem', sm: '2.5rem' },
                                    }}
                                >
                                    {text.completed}
                                </Typography>
                                <Button
                                    variant="contained"
                                    size="large"
                                    startIcon={<RestartAlt />}
                                    onClick={startNewRound}
                                    sx={{
                                        backgroundColor: '#FF6F61',
                                        color: '#fff',
                                        borderRadius: '25px',
                                        px: 5,
                                        py: 2,
                                        fontSize: '1.5rem',
                                        fontFamily: "'Comic Sans MS', cursive",
                                        boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
                                        '&:hover': { backgroundColor: '#FF5252' },
                                    }}
                                >
                                    {text.restart}
                                </Button>
                            </motion.div>
                        ) : (
                            /* Game play screen */
                            <>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        textAlign: 'center',
                                        mb: 3,
                                        fontFamily: "'Comic Sans MS', cursive",
                                        color: '#FF6F61',
                                        fontSize: { xs: '1.5rem', sm: '2rem' },
                                    }}
                                >
                                    {gameState.feedback === 'correct' ? gameState.currentWord.word : displayWord}
                                </Typography>

                                {gameState.currentWord && (
                                    <motion.div
                                        initial={{ scale: 0.9 }}
                                        animate={{ scale: 1 }}
                                        transition={{ type: 'spring', stiffness: 100 }}
                                        style={{ textAlign: 'center' }}
                                    >
                                        <img
                                            src={gameState.currentWord.image}
                                            alt="Word visual"
                                            style={{
                                                width: '100%',
                                                maxWidth: 250,
                                                height: 'auto',
                                                borderRadius: '20px',
                                                border: '4px solid #FFD700',
                                                boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                                                margin: '0 auto',
                                                display: 'block',
                                            }}
                                        />
                                    </motion.div>
                                )}

                                <Box
                                    sx={{
                                        display: 'grid',
                                        gridTemplateColumns: { xs: 'repeat(3, 1fr)' },
                                        gap: 2,
                                        mt: 4,
                                    }}
                                >
                                    <AnimatePresence>
                                        {gameState.options.map((option, index) => (
                                            <motion.div
                                                key={option}
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                exit={{ opacity: 0 }}
                                                transition={{ duration: 0.3 }}
                                            >
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    size="large"
                                                    onClick={() => handleAnswer(option)}
                                                    disabled={gameState.loading || gameState.feedback}
                                                    sx={{
                                                        backgroundColor: ['#FFAB91', '#FFCC80', '#A5D6A7'][index % 3], // Pastel colors
                                                        color: '#fff',
                                                        borderRadius: '20px',
                                                        py: 2,
                                                        fontSize: '1.5rem',
                                                        fontFamily: "'Comic Sans MS', cursive",
                                                        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                                        transition: 'transform 0.2s',
                                                        '&:hover': {
                                                            backgroundColor: ['#FF8A65', '#FFB74D', '#81C784'][index % 3],
                                                            transform: gameState.loading ? 'none' : 'scale(1.05)',
                                                        },
                                                        '&:disabled': {
                                                            backgroundColor: '#BDBDBD',
                                                            color: '#fff',
                                                        },
                                                    }}
                                                >
                                                    {option}
                                                </Button>
                                            </motion.div>
                                        ))}
                                    </AnimatePresence>
                                </Box>
                            </>
                        )}
                    </AnimatePresence>

                    {/* Feedback overlay */}
                    <AnimatePresence>
                        {gameState.feedback && !gameState.allWordsUsed && (
                            <motion.div
                                initial={{ scale: 0 }}
                                animate={{ scale: 1 }}
                                exit={{ scale: 0 }}
                                style={{
                                    position: 'absolute',
                                    top: '45%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    textAlign: 'center',
                                    backgroundColor: gameState.feedback === 'correct' ? 'rgba(76, 175, 80, 0.9)' : 'rgba(255, 82, 82, 0.9)',
                                    borderRadius: '20px',
                                    p: 3,
                                }}
                            >
                                {gameState.feedback === 'correct' ? (
                                    <CheckCircle sx={{ fontSize: 100, color: '#fff' }} />
                                ) : (
                                    <Cancel sx={{ fontSize: 100, color: '#fff' }} />
                                )}
                                <Typography
                                    variant="h4"
                                    sx={{
                                        mt: 2,
                                        fontFamily: "'Comic Sans MS', cursive",
                                        color: '#fff',
                                    }}
                                >
                                    {gameState.feedback === 'correct'
                                        ? text.correct(gameState.totalCorrect % 5 === 0 ? 4 : 2)
                                        : text.wrong}
                                </Typography>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </Paper>
            </Box>
        </Container>
    );
};

export default MissingLetterGame;
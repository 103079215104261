import React from 'react';
import {Box, Typography, Grid, Button, Modal, IconButton} from '@mui/material';
import { useCart } from 'react-use-cart';
import { useDispatch, useSelector } from 'react-redux';
import { closeCart } from "../store/slices/commonConfigSlice";
import {Add, Delete, Remove} from "@mui/icons-material";

const CartModal = () => {
    const { items, cartTotal, updateItemQuantity, removeItem, emptyCart } = useCart();
    const isCartOpen = useSelector((state) => state.commonConfig.isCartOpen);
    const dispatch = useDispatch();

    const deliveryFees = 8;
    const totalThemes = 15;
    const isAllThemesSelected = items.length === totalThemes;
    const remise = isAllThemesSelected ? 15 : 0; // 10 DT discount if all themes are selected
    const subTotal = cartTotal.toFixed(2);
    const total = (cartTotal - remise + deliveryFees).toFixed(2);

    const handleClose = () => {
        dispatch(closeCart());
    };

    return (
        <Modal
            open={isCartOpen}
            onClose={handleClose}
            aria-labelledby="cart-modal-title"
            aria-describedby="cart-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: { xs: '90%', md: '60%', lg: '40%' }, // Responsive width
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 0, // No padding on the box for the modal
                    borderRadius: 2,
                    maxHeight: '90vh',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/* Fixed Header */}
                <Box
                    sx={{
                        backgroundColor: '#f5f5f5',
                        padding: 2,
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                    }}
                >
                    <Typography id="cart-modal-title" variant="h6" component="h2">
                        Votre panier
                    </Typography>
                </Box>

                {/* Scrollable Content */}
                <Box
                    sx={{
                        overflowY: 'auto',
                        flexGrow: 1, // Take available space between header and footer
                        p: 2,
                    }}
                >
                    {/* Cart Items */}
                    {items.length === 0 ? (
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            Votre panier est vide.
                        </Typography>
                    ) : (
                        <>
                            {items.map((item) => (
                                <Grid container key={item.id} spacing={2} sx={{ mb: 2 }}>
                                    <Grid item xs={6}>
                                        <Typography variant="body1">{item.name}</Typography>
                                        <Typography variant="body2">Quantité: {item.quantity}</Typography>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography align="right">{parseInt(item.price) * item.quantity} DT</Typography>

                                    </Grid>
                                    <Grid item xs={4}>
                                        <IconButton
                                            color="success"
                                            size="small"
                                            onClick={() => updateItemQuantity(item.id, item.quantity + 1)}
                                        >
                                            <Add/>
                                        </IconButton>
                                        <IconButton
                                            color="error"
                                            size="small"
                                            onClick={() => updateItemQuantity(item.id, item.quantity - 1)}
                                            disabled={item.quantity <= 1}
                                        >
                                            <Remove/>
                                        </IconButton>
                                        <IconButton
                                            onClick={() => removeItem(item.id)}
                                            variant="contained"

                                        >
                                            <Delete
                                                size="small"
                                                color="error"
                                            />
                                        </IconButton>

                                    </Grid>

                                </Grid>
                            ))}
                        </>
                    )}
                </Box>

                {/* Fixed Total Price Section */}
                <Box
                    sx={{
                        backgroundColor: '#f5f5f5',
                        padding: 2,
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                        position: 'sticky',
                        bottom: 50, // Give space for action buttons
                        zIndex: 1,
                    }}
                >
                    {/* Subtotal, Discount, Delivery, and Total */}
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="body1">Sous-total:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align="right">{subTotal} DT</Typography>
                        </Grid>

                        {isAllThemesSelected && (
                            <>
                                <Grid item xs={6}>
                                    <Typography variant="body1">Remise (Tous les thèmes):</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography align="right">-{remise} DT</Typography>
                                </Grid>
                            </>
                        )}

                        <Grid item xs={6}>
                            <Typography variant="body1">Frais de livraison:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography align="right">{deliveryFees} DT</Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Typography variant="h6">Total:</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h6" align="right">
                                {total} DT
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                {/* Fixed Action Bar */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: 2,
                        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                        backgroundColor: '#f5f5f5',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={emptyCart}
                    >
                        Vider le panier
                    </Button>

                    <Button>
                        Commander
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}
                    >
                        Fermer
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default CartModal;

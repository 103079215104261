// store/index.js
import { configureStore } from '@reduxjs/toolkit';
import productsReducer from './slices/productsSlice';
import commonConfigReducer from "./slices/commonConfigSlice";

const store = configureStore({
    reducer: {
        products: productsReducer, // Register products slice here
        commonConfig : commonConfigReducer
    },
});

export default store;

import React, { useState, useEffect } from 'react';
import { IconButton, Typography, Grid, Stack } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';



const RamadanNotebookCarousel = ({notebookModels}) => {
    const [currentModelIndex, setCurrentModelIndex] = useState(0);

    // Handle next model
    const handleNext = () => {
        setCurrentModelIndex((prevIndex) => (prevIndex + 1) % notebookModels.length);
    };

    // Handle previous model
    const handlePrevious = () => {
        setCurrentModelIndex(
            (prevIndex) => (prevIndex - 1 + notebookModels.length) % notebookModels.length
        );
    };

    // Auto-carousel functionality
    useEffect(() => {
        const interval = setInterval(() => {
            handleNext();
        }, 5000); // Advance to the next model every 5 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, [currentModelIndex]); // Re-run effect when currentModelIndex changes

    return (
        <Grid container sx={{ position: 'relative', width: '100%', height: '100%', maxWidth: '100%', mx: 'auto' }}>
            {/* Left Arrow */}
            <IconButton
                onClick={handlePrevious}
                sx={{
                    position: 'absolute',
                    left: { xs: -20, sm: -40, md: -60 },
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    bgcolor: 'rgba(255, 255, 255, 0.8)',
                    '&:hover': { bgcolor: 'rgba(255, 255, 255, 1)' },
                }}
            >
                <ChevronLeft fontSize="large" />
            </IconButton>

            {/* Right Arrow */}
            <IconButton
                onClick={handleNext}
                sx={{
                    position: 'absolute',
                    right: { xs: -20, sm: -40, md: -60 },
                    top: '50%',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    bgcolor: 'rgba(255, 255, 255, 0.8)',
                    '&:hover': { bgcolor: 'rgba(255, 255, 255, 1)' },
                }}
            >
                <ChevronRight fontSize="large" />
            </IconButton>

            {/* Carousel Content */}
            <Grid container sx={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden' }}>
                <AnimatePresence initial={false} custom={currentModelIndex}>
                    <motion.div
                        key={currentModelIndex}
                        custom={currentModelIndex}
                        initial={{ opacity: 0, x: 100 }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: -100 }}
                        transition={{ duration: 0.5 }}
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Stack spacing={2} alignItems="center">
                            <img
                                src={notebookModels[currentModelIndex].image}
                                alt={`Ramadan Notebook Model ${currentModelIndex + 1}`}
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    maxHeight: '80%',
                                    objectFit: 'contain',
                                    borderRadius: 8,
                                }}
                            />
                            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#2e7d32' }}>
                                {notebookModels[currentModelIndex].name}
                            </Typography>
                        </Stack>
                    </motion.div>
                </AnimatePresence>
            </Grid>
        </Grid>
    );
};

export default RamadanNotebookCarousel;
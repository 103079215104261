import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Typography from '@mui/material/Typography';
import moment from "moment";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {Stack} from "@mui/material";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

export default function DeliveryTimeline({order}) {
    const orderDate = moment(order.created_at); // Order reception date

    // Order will be shipped within 2 days max
    const shippedDate = moment(orderDate).add(2, 'days');

    // Delivery window starts the day after shipping and lasts 1-2 days
    const deliveryStart = moment(shippedDate).add(1, 'days');
    const deliveryEnd = moment(shippedDate).add(2, 'days');

    return (
        <Timeline position="alternate">
            {/* Order Reception */}
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    {orderDate.format("DD-MM-Y")}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineDot color="success">
                        <DoneAllIcon sx={{ color: "white" }} />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6">Réception de la commande</Typography>
                    <Typography>Commande reçue. Merci pour votre confiance.</Typography>
                </TimelineContent>
            </TimelineItem>

            {/* Order Shipped */}
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="left"
                    variant="body2"
                    color="text.secondary"
                >
                    {shippedDate.format("DD-MM-Y")}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="primary">
                        <ExitToAppIcon />
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6">Commande sera expédiée</Typography>
                </TimelineContent>
            </TimelineItem>

            {/* Estimated Delivery */}
            <TimelineItem>
                <TimelineOppositeContent
                    sx={{ m: 'auto 0' }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                >
                    Entre le {deliveryStart.format("DD-MM-Y")} et le {deliveryEnd.format("DD-MM-Y")}
                </TimelineOppositeContent>
                <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot color="warning">
                        <LocalShippingIcon />
                    </TimelineDot>
                </TimelineSeparator>
                <TimelineContent sx={{ py: '12px', px: 2 }}>
                    <Typography variant="h6">Livraison estimée</Typography>

                </TimelineContent>
            </TimelineItem>
        </Timeline>
    );
}

import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Button, Container, Typography, Box, Grid, Stack, Card, CardContent } from '@mui/material';
import { fetchSingleProduct } from "../store/slices/productsSlice";
import { useDispatch } from "react-redux";
import moment from "moment";
import { frontApiCall } from "../api/config";
import AddProductToCard from "../Component/AddProductToCard";

// Animation variants
const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: 'easeOut' } },
};

const staggerContainer = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.2 } },
};

const productVariants = {
    hidden: { opacity: 0, scale: 0.9 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            duration: 0.6,
            ease: 'easeOut',
            staggerChildren: 0.1
        }
    },
    hover: {
        scale: 1.03,
        boxShadow: "0px 10px 20px rgba(0,0,0,0.1)",
        transition: { duration: 0.3 }
    }
};

const WriteEraseProducts = () => {
    const orderSection = useRef(null);
    const [products, setProducts] = useState([]);
    const [promos, setPromos] = useState({});
    const [daysLeft, setDaysLeft] = useState({});
    const dispatch = useDispatch();

    const productIds = [252, 253, 254]; // Arabic, French, Pre-writing versions

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productsData = await Promise.all(
                    productIds.map(id => dispatch(fetchSingleProduct(id)))
                );

                const loadedProducts = productsData
                    .filter(res => res.payload && res.payload.product)
                    .map(res => res.payload.product);

                setProducts(loadedProducts);
            } catch (error) {
                console.error("Failed to fetch products:", error);
            }
        };

        const fetchPromos = async () => {
            try {
                const res = await frontApiCall().get("promos/all");
                const allPromos = res.data.promos;
                const promosMap = {};
                const daysLeftMap = {};

                productIds.forEach(id => {
                    const productPromos = allPromos.filter(p =>
                        p.products.some(prod => prod.id === id)
                    );

                    if (productPromos.length > 0) {
                        const highestPromo = productPromos.reduce((prev, current) =>
                            (parseInt(prev.reduction) > parseInt(current.reduction)) ? prev : current
                        );
                        promosMap[id] = highestPromo;

                        const endDate = moment(highestPromo.end);
                        const now = moment();
                        const diff = endDate.diff(now, 'days');
                        daysLeftMap[id] = diff > 0 ? diff : 0;
                    }
                });

                setPromos(promosMap);
                setDaysLeft(daysLeftMap);
            } catch (error) {
                console.error("Failed to fetch promos:", error);
            }
        };

        fetchProducts();
        fetchPromos();
    }, [dispatch]);

    const scrollToOrder = () => {
        orderSection.current.scrollIntoView({ behavior: 'smooth' });
    };

    const getDiscountedPrice = (product) => {
        if (!product || !promos[product.id]) return parseInt(product?.price || 0);
        const originalPrice = parseInt(product.price);
        const discount = parseInt(promos[product.id].reduction);
        return originalPrice * (1 - discount / 100);
    };

    const productTitles = {
        252: "كرّاس الحروف العربية و الأرقام",
        253: "كرّاس الحروف الفرنسية و الأرقام",
        254: "كرّاس مهارات ما قبل الكتابة"
    };

    const productDescriptions = {
        252: [
            "تعلم الحروف العربية والأرقام من 01 إلى 10",
            "مناسب للأطفال من 4 سنوات فما فوق",
            "صفحات قابلة للمسح وإعادة الكتابة",
            "مقاس 22 سم × 30 سم"
        ],
        253: [
            "Apprendre les lettres françaises et les chiffres de 01 à 10",
            "Pour les enfants de 6 ans et plus",
            "Pages effaçables et réutilisables",
            "Dimensions: 22 cm × 30 cm"
        ],
        254: [
            "تمارين ما قبل الكتابة والتخطيط",
            "مناسب للأطفال من 3 سنوات فما فوق",
            "صفحات قابلة للمسح وإعادة الكتابة",
            "مقاس 22 سم × 30 سم"
        ]
    };

    return (
        <Grid container justifyContent="center" sx={{ py: 4 }}>
            <Container maxWidth="lg">
                <motion.div initial="hidden" animate="visible" variants={staggerContainer}>
                    {/* Main Header */}
                    <motion.div variants={fadeInUp}>
                        <Typography
                            variant="h2"
                            sx={{
                                fontWeight: 'bold',
                                color: 'primary.main',
                                textAlign: 'center',
                                mb: 2
                            }}
                        >
                            كرّاسات الكتابة والإعادة
                        </Typography>
                    </motion.div>

                    <motion.div variants={fadeInUp}>
                        <Typography
                            variant="h5"
                            sx={{
                                color: 'text.secondary',
                                textAlign: 'center',
                                mb: 4
                            }}
                        >
                            قابلة للمسح وإعادة الاستعمال مراراً وتكراراً
                        </Typography>
                    </motion.div>

                    {/* Products Grid */}
                    <Grid container spacing={4} sx={{ mb: 6 }}>
                        {products.map((product) => (
                            <Grid item xs={12} md={4} key={product.id}>
                                <motion.div
                                    variants={productVariants}
                                    initial="hidden"
                                    whileInView="visible"
                                    viewport={{ once: true, margin: "-50px" }}
                                    whileHover="hover"
                                >
                                    <Card sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        borderRadius: '12px',
                                        boxShadow: 3
                                    }}>
                                        <Box
                                            component="img"
                                            src={`/write-erase/${product.id === 252 ? 'arabic' :
                                                product.id === 253 ? 'french' : 'prewriting'}.png`}
                                            alt={productTitles[product.id]}
                                            sx={{
                                                width: '100%',
                                                height: 'auto',
                                                borderTopLeftRadius: '12px',
                                                borderTopRightRadius: '12px'
                                            }}
                                        />
                                        <CardContent sx={{ flexGrow: 1 }}>
                                            <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                                                {productTitles[product.id]}
                                            </Typography>

                                            <Stack spacing={1} sx={{ mb: 2 }}>
                                                {productDescriptions[product.id].map((desc, index) => (
                                                    <Typography key={index} variant="body2">
                                                        • {desc}
                                                    </Typography>
                                                ))}
                                            </Stack>

                                            <Box sx={{ mt: 'auto' }}>
                                                {promos[product.id] ? (
                                                    <>
                                                        <Typography variant="body2" sx={{ textDecoration: 'line-through' }}>
                                                            {parseInt(product.price).toFixed(0)} د.ت
                                                        </Typography>
                                                        <Typography variant="h6" sx={{ color: 'error.main', fontWeight: 'bold' }}>
                                                            {getDiscountedPrice(product).toFixed(0)} د.ت
                                                        </Typography>
                                                        <Typography variant="caption" sx={{ color: 'error.main' }}>
                                                            خصم {promos[product.id].reduction}% (متاح لـ {daysLeft[product.id]} أيام فقط)
                                                        </Typography>
                                                    </>
                                                ) : (
                                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                                        {parseInt(product.price).toFixed(0)} د.ت
                                                    </Typography>
                                                )}
                                            </Box>
                                        </CardContent>
                                        <Box sx={{ p: 2 }}>
                                            <AddProductToCard
                                                product={{
                                                    ...product,
                                                    price: promos[product.id] ? getDiscountedPrice(product) : product.price,
                                                    originalPrice: parseInt(product.price),
                                                    promo: promos[product.id] ? {
                                                        id: promos[product.id].id,
                                                        reduction: promos[product.id].reduction,
                                                        end: promos[product.id].end
                                                    } : null
                                                }}
                                                fullWidth
                                            />
                                        </Box>
                                    </Card>
                                </motion.div>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Additional Content */}
                    <motion.div variants={fadeInUp}>
                        <Typography variant="h4" sx={{ textAlign: 'center', mb: 3, color: 'primary.dark' }}>
                            مميزات كرّاساتنا
                        </Typography>
                    </motion.div>

                    <Grid container spacing={3} sx={{ mb: 6 }}>
                        {[
                            "صفحات مغلفة حرارياً بمادة البلاستيك المتين",
                            "قابلة للكتابة بالقلم الخاص والمسح بسهولة",
                            "تناسب جميع أنواع أقلام السبورة البيضاء",
                            "تصميم ممتع يحفز الأطفال على التعلم",
                            "مناسبة للاستخدام المنزلي والمدرسي"
                        ].map((feature, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <motion.div variants={fadeInUp}>
                                    <Card sx={{ p: 2, height: '100%' }}>
                                        <Typography variant="body1" sx={{ textAlign: 'center' }}>
                                            {feature}
                                        </Typography>
                                    </Card>
                                </motion.div>
                            </Grid>
                        ))}
                    </Grid>

                    <div ref={orderSection}>
                        <motion.div variants={fadeInUp}>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={scrollToOrder}
                                sx={{
                                    display: 'block',
                                    mx: 'auto',
                                    px: 6,
                                    py: 1.5,
                                    borderRadius: '8px',
                                    fontSize: '1.1rem',
                                    fontWeight: 'bold'
                                }}
                            >
                                اطلب الآن
                            </Button>
                        </motion.div>
                    </div>
                </motion.div>
            </Container>
        </Grid>
    );
};

export default WriteEraseProducts;